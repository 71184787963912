import 'moment-timezone'
import 'moment/locale/ru'
import { i18n } from '@/i18n'

import { addZoneToStopList, restoreZoneFromStopList } from '../../functions/stopList/delivery_zone'
import { getInfoAboutClosingZone, getTime, saveDeliveryTime } from '../../functions/times/delivery_time'
import { getZones } from '../../functions/delivery_zone'

import moment from 'moment'

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    zones: [],
    modal: {
      visible: false,
      zone: Object,
      loading: false,
      inactive: {
        status: false,
        inactiveTime: null
      },
      time: {
        delivery: 0,
        default: 0,
        minValue: 0,
        colors: {
          track_color: '#bdbdbd'
        },
        min: 0,
        max: 240,
        step: 5
      },
      update: {
        visible: false,
        form: {
          date: null,
          time: null
        }
      },
      stopList: {
        visible: false,
        modal: {
          visible: false,
          title: '',
          params: {}
        }
      }
    }
  }),

  mutations: {
    changeLoading (state, val) {
      state.loading = val
    },
    changeInactiveStatus (state, val) {
      if (state.modal.zone.id !== undefined) {
        state.zones.find(zone => zone.id === state.modal.zone.id).inactive.status = val
      }

      state.modal.inactive.status = val
    },
    changeUpdateStatus (state, val) {
      state.modal.update.visible = val
    },
    changeModalVisible (state, val) {
      state.modal.visible = val
    },
    changeStopListStatus (state, val) {
      state.modal.stopList.visible = val
    },
    changeStopListModalVisible (state, val) {
      state.modal.stopList.modal.visible = val
    },
    clear (state) {
      state.modal.time.delivery = 0
      state.modal.inactive = {
        status: false,
        inactiveTime: null
      }
      state.modal.stopList.visible = false
      state.modal.update = {
        visible: false,
        form: {
          date: null,
          time: null
        }
      }
    },
    setStopList (state, val) {
      state.modal.stopList = {
        ...state.modal.stopList,
        ...val
      }
    },
    setStopListModal (state, val) {
      state.modal.stopList.modal = {
        ...state.modal.stopList.modal,
        ...val
      }
    },
    setUpdate (state, val) {
      state.modal.update = {
        ...state.modal.update,
        ...val
      }
    },
    setTime (state, val) {
      state.modal.time = {
        ...state.modal.time,
        ...val
      }
    },
    setDefaultTime (state) {
      state.modal.update.form.time = moment().add(30, 'minutes').format('HH:mm')
    },
    setInactive (state, val) {
      state.modal.inactive = {
        ...state.modal.inactive,
        ...val
      }
    }
  },

  getters: {
    loading (state) {
      return state.loading
    },
    modal (state) {
      return state.modal
    },
    zones (state) {
      return state.zones
    },
    headers (state) {
      return [
        { text: i18n.t('color'), value: 'color', align: 'center' },
        { text: i18n.t('zone_name'), value: 'name' },
        { text: i18n.t('delivery_time'), align: 'center', value: 'delivery_time' },
        { text: '', value: 'actions', width: 230 }
      ]
    },
    timeMarks (state) {
      const labels = []
      labels[state.modal.time.min / 5] = `${state.modal.time.min}`
      labels[state.modal.time.delivery / 5] = i18n.t('time.delay_minutes', { time: state.modal.time.delivery })
      labels[state.modal.time.max / 5] = `${state.modal.time.max}`

      return labels
    },
    min (state) {
      return state.modal.zone.delivery_time
    },
    dates (state) {
      const now = moment().locale(i18n.locale)
      const addDay = moment(now).add(1, 'day')
      const add2Day = moment(now).add(2, 'day')
      const add3Day = moment(now).add(3, 'day')
      state.modal.update.form.date = moment(now).format('YYYY-MM-DD')

      return [
        { text: i18n.t('today'), value: moment(now).format('YYYY-MM-DD') },
        { text: moment(addDay).format('D MMMM'), value: moment(addDay).format('YYYY-MM-DD') },
        { text: moment(add2Day).format('D MMMM'), value: moment(add2Day).format('YYYY-MM-DD') },
        { text: moment(add3Day).format('D MMMM'), value: moment(add3Day).format('YYYY-MM-DD') }
      ]
    }
  },

  actions: {
    addZoneToStopList,
    restoreZoneFromStopList,
    getZones,
    saveDeliveryTime,
    getInfoAboutClosingZone,
    getTime,
    getCustomTime ({ state, commit, getters, dispatch }) {
      commit('changeLoading', true)

      dispatch('getTime', state.modal.zone.id).then(response => {
        if (response.data.time !== null) {
          commit('setTime', { delivery: response.data.time })
        } else {
          commit('setTime', { delivery: getters.min })
        }

        commit('setTime', { default: state.modal.time.delivery })
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    getDeliveryZones ({ dispatch, commit, state, rootState }) {
      commit('changeLoading', true)

      dispatch('getZones', rootState.user.id).then(response => {
        state.zones = response.data
      }).catch(error => {
        dispatch('showSnackbar', error.response.data.message, { root: true })
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    restore ({ dispatch, commit, state }, id) {
      commit('changeLoading', true)

      dispatch('restoreZoneFromStopList', id).then(response => {
        dispatch('showSnackbar', i18n.t('stop_list.success.zone_restore_from_stop_list'), { root: true })

        commit('changeInactiveStatus', false)
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    edit ({ dispatch, commit, state, rootState }, id) {
      commit('changeModalVisible', true)
      state.modal.zone = state.zones.find(zone => zone.id === id)
      dispatch('getInfoAboutClosing')
      dispatch('getCustomTime')
    },

    save ({ dispatch, commit, state }) {
      commit('changeLoading', true)

      const data = {
        id: state.modal.zone.id,
        date: state.modal.update.form.date,
        time: state.modal.update.form.time,
        minutes: state.modal.time.delivery
      }

      if (!data.date || !data.time || !data.minutes) {
        dispatch('showSnackbar', i18n.t('data_not_all_provided'), { root: true })
        commit('changeLoading', false)
        return false
      }

      dispatch('saveDeliveryTime', data).then(response => {
        dispatch('showSnackbar', i18n.t('text.data_updated'), { root: true })
        commit('setUpdate', {
          visible: false,
          form: {
            date: null,
            time: null
          }
        })
        dispatch('getDeliveryZones')
        commit('setTime', { default: state.modal.time.delivery })
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    getInfoAboutClosing ({ commit, state, dispatch }) {
      commit('changeLoading', true)

      dispatch('getInfoAboutClosingZone', state.modal.zone.id).then(response => {
        if (response.data.inactiveTime !== undefined) {
          commit('changeInactiveStatus', true)
          commit('setInactive', { inactiveTime: response.data.inactiveTime })
        }
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    shopToStopList ({ commit, rootState }) {
      commit('setStopListModal', {
        visible: true,
        title: rootState.user.name,
        params: {
          shop: rootState.user.id
        }
      })
    },
    updatePageAfterStopZone ({ commit, dispatch, state }) {
      commit('clear')
      dispatch('getInfoAboutClosing')
      commit('changeInactiveStatus', true)
      commit('setTime', { delivery: state.modal.time.default })
    },
    updateValue ({ commit, state }, val) {
      if (val <= state.time.minValue) {
        val = state.time.minValue
      }
      commit('setTime', { delivery: val })
    },

    saveStopList ({ dispatch, commit, state, rootState }, data) {
      commit('changeLoading', true)

      const request = {
        city: rootState.user.city_id,
        zone: state.modal.zone.id,
        suspendFrom: false,
        date: data.period,
        reason: data.reason
      }

      if (!request.city || !request.zone || !request.reason || !request.date) {
        dispatch('showSnackbar', i18n.t('data_not_all_provided'), { root: true })
        commit('changeLoading', false)
        return false
      }

      if (request.date === 'enter') {
        request.date = data.enterPeriod.day * 24 * 60
        request.date += data.enterPeriod.hour * 60
        request.date += data.enterPeriod.minute
      }

      dispatch('addZoneToStopList', request).then(response => {
        dispatch('showSnackbar', i18n.t('stop_list.success.zone_added_to_stop_list'), { root: true })

        dispatch('updatePageAfterStopZone')
        commit('changeStopListModalVisible', false)
      }).finally(() => {
        commit('changeLoading', false)
      })
    }
  }
}
