import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import axios from 'axios'
import VueAxios from 'vue-axios'
import settings from './config/settings'
import axiosInterceptors from './functions/axios/axios_interceptors'

import * as Sentry from '@sentry/vue'

import cookingIcon from '@/assets/order_statuses/cooking.svg'
import canceledIcon from '@/assets/order_statuses/canceled.svg'
import closedIcon from '@/assets/order_statuses/closed.svg'
import deliveringIcon from '@/assets/order_statuses/delivering.svg'
import readyIcon from '@/assets/order_statuses/ready.svg'
import readyForDeliveryIcon from '@/assets/order_statuses/ready_for_delivery.svg'
import waitingAcceptIcon from '@/assets/order_statuses/waiting_accept.svg'
import waitingCookingIcon from '@/assets/order_statuses/waiting_cooking.svg'
import noDataIcon from '@/assets/order_table/order_table_no_data.svg'
import noDataXmasIcon from '@/assets/order_table/order_table_no_data_xmas.svg'
import warningIcon from '@/assets/order_table/warning_icon.svg'
import editIcon from '@/assets/icons/edit.svg'
import Router from 'vue-router'

Sentry.init({
  Vue,
  dsn: settings.SENTRY_DSN,

  integrations: [
    // Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration()
  ],
  debug: process.env.NODE_ENV === 'development'
  // tracingOptions: {
  //   trackComponents: true
  // },
  // tracePropagationTargets: [/^http:\/\/localhost:8080/],
  // logErrors: true,
  // attachProps: true,
  // attachStacktrace: true
  // tracesSampleRate: 0.25
})

Vue.use(VueAxios, axios)
axios.defaults.baseURL = settings.API_HOST
Vue.use(axiosInterceptors, axios)
Vue.prototype.$storeGlobal = store

Vue.use(Router)
Vue.component('cookingIcon', cookingIcon)
Vue.component('canceledIcon', canceledIcon)
Vue.component('closedIcon', closedIcon)
Vue.component('deliveringIcon', deliveringIcon)
Vue.component('readyIcon', readyIcon)
Vue.component('readyForDeliveryIcon', readyForDeliveryIcon)
Vue.component('waitingAcceptIcon', waitingAcceptIcon)
Vue.component('waitingCookingIcon', waitingCookingIcon)
Vue.component('noDataIcon', noDataIcon)
Vue.component('noDataXmasIcon', noDataXmasIcon)
Vue.component('warningIcon', warningIcon)
Vue.component('EditIcon', editIcon)

new Vue({
  i18n,
  store,
  router,
  vuetify,
  template: '<App/>',
  render: h => h(App)
}).$mount('#app')
