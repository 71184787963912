import { render, staticRenderFns } from "./OtherSellerModal.vue?vue&type=template&id=a5177420&scoped=true"
import script from "./OtherSellerModal.vue?vue&type=script&lang=js"
export * from "./OtherSellerModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5177420",
  null
  
)

export default component.exports