import { i18n } from '../../i18n'

export default {
  install (Vue, axios, params = {}) {
    const showErrorMessage = (response, type = '', single = false) => {
      if (!single && !response.data) return

      if (single && !response) return

      if (single) {
        Vue.prototype.$storeGlobal.dispatch('showSnackbar', response)
        return
      }

      if (typeof response.data === 'string') {
        Vue.prototype.$storeGlobal.dispatch('showSnackbar', response)
        return
      }

      if (typeof response.data.errors === 'string') {
        Vue.prototype.$storeGlobal.dispatch('showSnackbar', {
          message: response.data.errors,
          type: type
        })
        return
      }

      if (response.data.errors) {
        showMessageFromObject(response.data.errors, type)
        return
      }

      if (response.data.message) {
        showMessageFromObject(response.data.message, type)
        return
      }

      if (response.data.validation) {
        showMessageFromObject(response.data.validation, type)
        return
      }

      showMessageFromObject(response.data, type)
    }

    const showMessageFromObject = (object, type) => {
      if (typeof object === 'string') {
        Vue.prototype.$storeGlobal.dispatch('showSnackbar', {
          message: object,
          type: type
        })
        return
      }

      const keys = Object.keys(object)
      if (keys.length === 0) {
        Vue.prototype.$storeGlobal.dispatch('showSnackbar', {
          message: i18n ? i18n.t('error') : 'Error',
          type: type
        })
        return
      }

      keys.map(key => {
        let message = object[key]
        if (Array.isArray(message)) {
          message = message[0]
        }

        setTimeout(() => {
          Vue.prototype.$storeGlobal.dispatch('showSnackbar', {
            message: message,
            type: type
          })
        }, 100)
      })
    }

    Object.defineProperty(Vue.prototype, name, {
      value: axios.interceptors.response.use((response) => {
        return response
      },
      (error) => {
        const originalRequest = error.config
        if (error.response && (error.response.status >= 400 && error.response.status <= 503)) {
          if (error.response.status === 401) {
            originalRequest._retry = true
            Vue.prototype.$storeGlobal.dispatch('logout')
            showErrorMessage(error.response)
            Vue.prototype.$storeGlobal.dispatch('logout')
          }

          if (error.response.status === 400 || (error.response.status > 401 && error.response.status < 500)) {
            showErrorMessage(error.response, 'warning')
          }

          if (error.response.status === 500) {
            showErrorMessage(i18n ? i18n.t('errors.server_error') : 'Error', 'error', true)
          }
        }

        if (error.message === 'Network Error') {
          showErrorMessage(i18n ? i18n.t('errors.server_connection_error') : 'Network Error', 'error', true)
        }

        return Promise.reject(error)
      })
    })
  }
}
