var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-xs":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-alert',{attrs:{"value":true,"color":"info","icon":"mdi-information","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('reports.desc'))+" ")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('v-btn',{attrs:{"color":"success","block":"","dark":"","large":""},nativeOn:{"click":function($event){return _vm.getReport()}}},[_vm._v(_vm._s(_vm.$t('reports.generate_report')))])],1)],1)],1)],1)],1)],1),(_vm.reportData.length !== 0)?_c('v-row',{staticClass:"mb-5"},[_c('v-col',{attrs:{"sm":"12","md":"7"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.productsHeader,"items":_vm.reportData.products,"items-per-page":10,"no-data-text":_vm.$t('table.no_information_available'),"loading-text":_vm.$t('loading'),"footer-props":{
            itemsPerPageAllText: _vm.$t('all'),
            itemsPerPageText: _vm.$t('pagination.entries_on_page')
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('reports.positions')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true}],null,false,3887931930)})],1),_c('v-col',{attrs:{"sm":"12","md":"5"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.paymentsHeader,"items":_vm.reportData.payments,"items-per-page":10,"no-data-text":_vm.$t('table.no_information_available'),"loading-text":_vm.$t('loading'),"footer-props":{
            itemsPerPageAllText: _vm.$t('all'),
            itemsPerPageText: _vm.$t('pagination.entries_on_page')
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('reports.types_payments')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true}],null,false,4165265069)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }