export default {
  namespaced: true,

  state: {

  },

  actions: {

  }
}
