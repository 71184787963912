import { render, staticRenderFns } from "./OrdersListClosed.vue?vue&type=template&id=620bf4f1&scoped=true"
import script from "./OrdersListClosed.vue?vue&type=script&lang=js"
export * from "./OrdersListClosed.vue?vue&type=script&lang=js"
import style0 from "./OrdersListClosed.vue?vue&type=style&index=0&id=620bf4f1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "620bf4f1",
  null
  
)

export default component.exports