<template>
  <v-container grid-list-xs fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-text>
            <v-layout row justify-center>
              <v-flex xs6>
                <v-alert
                    :value="true"
                    color="info"
                    icon="mdi-information"
                    outlined>
                  {{ $t('reports.desc') }}
                </v-alert>
              </v-flex>
            </v-layout>
            <v-layout row justify-center>
              <v-flex xs6>
                <v-btn color="success" block dark large @click.native="getReport()">{{ $t('reports.generate_report') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-row class="mb-5" v-if="reportData.length !== 0">
      <v-col sm="12" md="7">
        <v-data-table
            :headers="productsHeader"
            :items="reportData.products"
            :items-per-page="10"
            :no-data-text="$t('table.no_information_available')"
            :loading-text="$t('loading')"
            :footer-props="{
              itemsPerPageAllText: $t('all'),
              itemsPerPageText: $t('pagination.entries_on_page')
            }"
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('reports.positions') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>

      <v-col sm="12" md="5">
        <v-data-table
            :headers="paymentsHeader"
            :items="reportData.payments"
            :items-per-page="10"
            :no-data-text="$t('table.no_information_available')"
            :loading-text="$t('loading')"
            :footer-props="{
              itemsPerPageAllText: $t('all'),
              itemsPerPageText: $t('pagination.entries_on_page')
            }"
            class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{ $t('reports.types_payments') }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Iiko43Report',
  computed: {
    productsHeader () {
      return [
        { text: this.$t('reports.table.column.name'), align: 'start', value: 'Name' },
        { text: this.$t('reports.table.column.quantity'), align: 'center', value: 'Quantity' },
        { text: this.$t('reports.table.column.amount'), align: 'center', value: 'Amount' }
      ]
    },
    paymentsHeader () {
      return [
        { text: this.$t('reports.table.column.name'), align: 'start', value: 'Name' },
        { text: this.$t('reports.table.column.amount'), align: 'center', value: 'Amount' }
      ]
    },
    reportData () {
      return this.$store.getters.getReport
    },
    errors () {
      return this.$store.getters.getErrors
    }
  },
  watch: {
    reportData (val) {
      if (val.length !== 0) {
        this.$store.state.loading = false
      }
    },
    errors (val) {
      if (val.length !== 0) {
        this.loading = false
      }
    }
  },
  methods: {
    getReport () {
      this.clearData()
      this.clearErrors()
      this.$store.state.loader.text = this.$t('loader.loading_data')
      this.$store.state.loading = true

      this.$store.state.worker.postMessage({ lang: this.$i18n.locale, type: 'report43' })
    },
    clearData () {
      this.$store.dispatch('clearReportData')
    },
    clearErrors () {
      this.$store.dispatch('clearErrors')
    },
    getErrors () {
      this.errors = this.$store.getters.errors
    }
  },
  created () {
    this.clearData()
  }
}
</script>
