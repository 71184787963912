import axios from 'axios'
import settings from '@/config/settings'

const API_HOST = settings.API_HOST

export function getInfo (id) {
  return axios.get(API_HOST + '/kassa/informationAboutProgram/' + id)
}

export function sendBackend (method, url, data) {
  return axios({
    method,
    url: API_HOST + url,
    data
  })
}
