<template>
  <div>
    <div>
      <div class="d-flex justify-space-between mb-1">
        <span style="color: black">{{ $t('plugin.info_about_plugin') }}</span>
        <v-btn color="primary" small @click="getPluginInfo">
          {{ $t('plugin.update_info') }}
        </v-btn>
      </div>
      <v-card class="mx-auto" :loading="plugin.loading" :disabled="plugin.loading">
        <v-card-text>
          <v-row>
            <v-col cols="5">
              {{ $t('version.self') }}
            </v-col>
            <v-col cols="7">
              {{ plugin.version }}
            </v-col>
          </v-row>
          <v-row cols="5">
            <v-col>
              {{ $t('plugin.id_plugin') }}
            </v-col>
            <v-col cols="7">
              {{ plugin.idShop }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              {{ $t('plugin.id_device') }}
            </v-col>
            <v-col cols="7">
              {{ plugin.idDevice }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              {{ $t('plugin.license_module') }}
            </v-col>
            <v-col cols="7">
              {{ plugin.licenseModule }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <v-divider class="my-4"></v-divider>

    <div>
      <span style="color: black">{{ $t('text.active_plugin_ids') }}</span>
      <v-card class="mx-auto" :loading="sync.loading">
        <v-card-text>
          <v-row>
            <v-col cols="3" class="d-flex align-center">
              <ul style="list-style-type: none">
                <li v-for="(item, i) in pluginIds" :key="i" class="my-2">
                  <span style="color: black">{{ item }}</span>
                </li>
              </ul>
            </v-col>
            <v-col cols="9" class="d-flex align-center justify-center">
              <div class="text-center">
                <v-btn @click="synchronize" small color="primary" :disabled="sync.loading">
                  {{ $t('sync') }}
                </v-btn>
                <v-alert
                  text
                  dense
                  color="grey darken-2"
                  style="font-size: 11px; padding: 2px">
                  {{ $t('text.when_click_button_delivery_program_sets_up_communication_with_iiko_plugin') }}
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import IIKO from '../../Models/IIKO/IIKO'

export default {
  name: 'IIKOInfo',
  data () {
    return {
      information: [],
      sync: {
        loading: false
      },
      plugin: {
        version: null,
        idShop: null,
        idDevice: null,
        licenseModule: null,
        loading: false
      }
    }
  },
  created () {
    this.getInformation()
    this.getPluginInfo()
  },
  computed: {
    pluginIds () {
      return this.information.reduce((out, item) => {
        out.push(item.plugin_id)
        return out
      }, [])
    }
  },
  methods: {
    getInformation () {
      this.sync.loading = true
      this.$store.dispatch('getInformationAboutProgram').then(response => {
        this.information = response.data
      }).catch(error => {
        if (error.response === undefined) {
          this.$store.dispatch('showSnackbar', this.$i18n.t('errors.no_connection'))
        } else {
          if (error.response.status === 400) {
            this.$store.dispatch('showSnackbar', error.response.data.message)
          } else {
            this.$store.dispatch('showSnackbar', this.$i18n.t('errors.server_error'))
          }
        }
      }).finally(() => {
        this.sync.loading = false
      })
    },
    getPluginInfo () {
      this.plugin.loading = true
      IIKO.getInfo().then(response => {
        const result = response.data.result

        this.plugin.version = result.version
        this.plugin.idShop = result.idShop
        this.plugin.idDevice = result.idDevice
        this.plugin.licenseModule = result.licenseModule
      }).catch(error => {
        if (!error.response || !error.response.data) {
          this.$store.dispatch('showSnackbar', this.$i18n.t('errors.iiko.iiko_not_running'))
        }
      }).finally(() => {
        this.plugin.loading = false
      })
    },
    synchronize () {
      this.sync.loading = true
      this.$store.dispatch('getInfoAboutPlugin').then(res => {
        if (res.status === 'success') {
          this.getInformation()
          res.message = this.$i18n.t('text.data_updated')
        }
        this.$store.dispatch('showSnackbar', this.$t(res.message))
      }).finally(() => {
        this.sync.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
