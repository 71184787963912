import { i18n } from '@/i18n'

export default {
  namespaced: true,
  state: {
    order: null,
    lock: false
  },
  mutations: {
    setOrder (state, value) {
      state.order = value
    },
    lock (state) {
      state.lock = true
    },
    unlock (state) {
      state.lock = false
    }
  },
  actions: {
    lock ({ commit }, order) {
      commit('setOrder', order)
      commit('lock')
    },
    unlock ({ commit }) {
      commit('setOrder', null)
      commit('unlock')
    },
    async close ({ dispatch, commit, rootState }, { order, modalType }) {
      await commit('modals/clear', modalType, { root: true })
      await rootState.eventBus.$emit('closeOrder', order)
      dispatch('unlock')
    },
    async complete ({ state, dispatch, commit, rootState }, { order, modalType }) {
      await order.complete()
      dispatch('close', { order, modalType })
    },
    async cancel ({ dispatch }, { order, modalType }) {
      dispatch('close', { order, modalType })
    },
    async handleOverdueOrder ({ state, dispatch, getters, rootGetters }, orderId) {
      if (state.lock) return

      const activeOrders = await rootGetters.getActiveOrders

      const order = activeOrders.find(item => item.id === orderId)

      if (!order) return

      dispatch('lock', order)

      dispatch('showInfoOrderModal', order)
    },

    async showInfoOrderModal ({ state, dispatch, commit, rootState }, order) {
      await rootState.eventBus.$emit('openOrder', order)

      let text = ''

      if (order.isDelivery) {
        text = `У заказа № <strong>${order.id}</strong> истекло время доставки. Просьба уточнить, заказ передан курьеру?`
      } else {
        text = `У заказа № <strong>${order.id}</strong> истекло время ожидания клиента. Просьба уточнить, клиент забрал свой заказ?`
      }

      dispatch('modals/showConfirmModal', {
        title: 'Забытый заказ',
        text,
        alert: { text: 'При положительном ответе, заказ будет завершен', type: 'warning' },
        buttons: {
          yes: {
            text: i18n.t('yes'),
            color: 'green lighten-2',
            action: () => {
              if (!order.iikoNumber) {
                dispatch('showOverdueOrderIIKOModal', order) // открываем модалку с отправкой заказа в iiko
                return
              }

              dispatch('complete', { order, modalType: 'confirm' }) // завершаем заказ если он был отправлен в iiko
            }
          },
          no: {
            text: i18n.t('no'),
            color: 'red lighten-2',
            action: () => {
              dispatch('cancel', { order, modalType: 'confirm' }) // закрываем текущую модалку
            }
          }
        }
      }, { root: true })
    },

    async showOverdueOrderIIKOModal ({ state, dispatch, commit, rootState }, order) {
      const text = `Заказ № <strong>${order.id}</strong> не перенесен в IIKO.`

      dispatch('modals/showConfirmModal', {
        title: 'Забытый заказ',
        text,
        alert: {
          text: 'Если заказ ранее был перенесен в IIKO, его можно привязать',
          type: 'warning'
        },
        buttons: {
          yes: {
            text: 'Перенести и завершить',
            color: 'green lighten-2',
            action: () => {
              rootState.eventBus.$on('sentToIIKO', (data) => {
                dispatch('complete', { order, modalType: 'confirm' }) // завершаем заказ после успешной отправки его в iiko
              })
              rootState.eventBus.$on('errorIIKO', (data) => {
                dispatch('cancel', { order, modalType: 'confirm' }) // закрываем модалку если произошла ошибка в интеграции с iiko
              })

              rootState.eventBus.$emit('openOrderPaymentTypes', { order, mode: 'iiko' }) // открываем модалку отправки заказа в iiko
            }
          },
          no: {
            text: 'Привязать и завершить',
            color: 'blue lighten-2',
            action: () => {
              dispatch('showOverdueOrderAttachIIKOModal', order) // открываем модалку с привязкой iiko number и заказа
              commit('modals/clear', 'confirm', { root: true }) // закрываем текущую модалку
            }
          }
        }
      }, { root: true })
    },

    async showOverdueOrderAttachIIKOModal ({ dispatch, rootState }, order) {
      dispatch('modals/showPromptModal', {
        title: 'Забытый заказ',
        fields: [
          {
            slug: 'iiko_number',
            value: null,
            label: 'Номер в IIKO',
            required: true
          }
        ],
        buttons: {
          enter: {
            text: i18n.t('save'),
            color: 'green lighten-2',
            value: null,
            action: (value) => {
              order.attachIIKONumber(value.iiko_number).then(response => {
                dispatch('complete', { order, modalType: 'prompt' }) // завершаем заказ после успешной связки с iiko number
              })
            }
          }
        }
      }, { root: true })
    }
  }
}
