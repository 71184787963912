<template>
  <div class="payment mr-2">
    <v-card
      flat
      style="border-radius: 4px"
      :color="disabled ? '#BBC4C8' : '#01579B'"
      @click="clickEvent"
    >
      <v-card-text class="card-payment-text d-flex align-center" :class="{ [backgroundPayment]: true }"
                   :style="{color: disabled ? 'black' : 'white'}">
        <component :is=paymentIcon :class="paymentClass"/>
        <div class="payment-name">{{ payment.name }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CardIcon from '@/assets/icons/card.svg'
import CashIcon from '@/assets/icons/cash.svg'
export default {
  name: 'PaymentTypeItem',
  props: {
    payment: Object,
    disabled: Boolean
  },
  computed: {
    backgroundPayment () {
      if (this.disabled) return 'payment-black'
      return 'payment-white'
    },
    paymentIcon () {
      switch (this.payment.slug) {
        case 'cash':
          return 'CashIcon'
        case 'card':
          return 'CardIcon'
        default:
          return ''
      }
    },
    paymentClass () {
      let classes = 'icon-payment'
      switch (this.payment.slug) {
        case 'cash':
          classes += ' cash-icon '
          break
        case 'card':
          classes += ' card-icon '
          break
      }
      return classes
    }
  },
  methods: {
    clickEvent () {
      if (!this.disabled) this.$emit('click', this.payment)
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CardIcon, CashIcon
  }
}
</script>

<style lang="scss">
.icon-payment{
  margin-right: 4px;
}
.card-icon{
  fill: black;
}
.payment {
  .card-payment-text.d-flex {
    padding: 13px 12px 13px 19px;
    height: 100%;
  }
}
.payment-black{
  fill: white;

  svg {
    fill: black;
    &.cash-icon{
      fill: none;
    }
  }
}
</style>
