import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

function checkDefaultLanguage () {
  let matched = null
  const languages = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })

  if (!matched) {
    languages.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }

  return matched
}

function selectedLocale () {
  return checkDefaultLanguage() || process.env.VUE_APP_I18N_LOCALE || 'en'
}

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (i18n.availableLocales.length !== 0) {
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }

    if (loadedLanguages.includes(lang)) {
      return Promise.resolve(setI18nLanguage(lang))
    }
  }

  return import(`@/locales/${lang}.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default || messages)
      loadedLanguages.push(lang)
      return Promise.resolve(setI18nLanguage(lang))
    }
  )
}

const numberFormats = {
  en: {
    currency: {
      style: 'currency', currency: 'try'
    }
  },
  tr: {
    currency: {
      style: 'currency', currency: 'try'
    }
  },
  ru: {
    currency: {
      style: 'currency', currency: 'rub'
    }
  }
}

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    full: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  tr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    full: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }
  },
  ru: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    full: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    }
  }
}

export const i18n = new VueI18n({
  locale: selectedLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  formatFallbackMessages: true,
  numberFormats,
  dateTimeFormats,
  messages: { }
})
