// eslint-disable-next-line no-unused-vars

import 'moment-timezone'
import 'moment/locale/ru'
import axios from 'axios'
import qs from 'qs'

import '@/helpers'
import config from '../../config/settings'

export default {
  state: {
    dialog: {
      show: false,
      canClose: false,
      news: [] // вдруг в диалоги могут быть другие новости?
    },
    news: [],
    newsRead: localStorage.getItem('newsReaded') ? JSON.safeParse(localStorage.getItem('newsReaded')) : [],
    loading: false
  },
  getters: {
    newsCount (state) {
      let count = 0

      // Считаем только непрочитанные
      for (const article of state.news) {
        if (!state.newsRead.find(id => parseInt(article.id) === id)) {
          count++
        }
      }

      return count
    },
    canCloseNews (state) {
      return state.dialog.canClose
    }
  },

  mutations: {
    readArticle (state, article) {
      state.loading = true
      article.read = true
      state.newsRead.push(parseInt(article.id))

      // localStorage.setItem('newsReaded', JSON.stringify(state.newsRead))

      const unreadedArticles = state.dialog.news.filter(item => item.read === false)

      if (!unreadedArticles.length) {
        state.dialog.canClose = true
      }

      state.loading = false
    },
    canCloseDialog (state) {
      state.dialog.canClose = true
    }
  },

  actions: {
    showNews ({ state, commit, rootState }, news, refresh = true) {
      state.dialog.show = true
      if (news && news.length) {
        state.dialog.news = news
      } else {
        state.dialog.news = state.news
      }

      if (!state.dialog.news.length && !state.loading) {
        state.dialog.canClose = true
      } else {
        state.dialog.canClose = false
      }

      this.dispatch('checkCanClose')

      // if (refresh && !state.loading) {
      //   this.dispatch('showNews')
      // }
    },

    checkCanClose ({ state, commit, rootState }) {
      let gotUnreaded = false
      for (const article of state.news) {
        if (!state.newsRead.find(id => parseInt(article.id) === id)) {
          gotUnreaded = true
        }
      }

      if (!gotUnreaded) {
        commit('canCloseDialog')
      }
    },

    hideNews ({ state, commit, rootState }) {
      state.dialog.show = false
    },
    closeNews ({ state, commit, rootState }) {
      state.dialog.show = false

      if (state.newsRead && state.newsRead.length) {
        this.dispatch('sendReaded')
      }
    },

    pushNews ({ state, commit, rootState }, news = []) {
      if (news && news.length) {
        for (const article of news) {
          if (!state.newsRead.find(id => parseInt(article.id) === id)) {
            article.read = false
          } else {
            article.read = true
          }
        }

        state.news = news
        this.dispatch('showNews', news)
        state.loading = false
      } else {
        state.loading = false
      }
    },

    loadNews ({ state, commit, rootState }, needOpen = true) {
      const self = this
      state.loading = true
      axios({
        method: 'post',
        url: config.API_HOST + '/kassa/news',
        data: qs.stringify({
          user_id: rootState.user.id,
          city_id: rootState.user.city_id,
          token: rootState.user.token
        })
      })
        .then(response => {
          const data = response.data
          const news = data.news

          if (news && news.length) {
            for (const article of news) {
              if (!state.newsRead.find(id => parseInt(article.id) === id)) {
                article.read = false
              } else {
                article.read = true
              }
            }

            state.news = news
            if (needOpen) {
              self.dispatch('showNews', news)
            }
            state.loading = false
          } else {
            state.loading = false
          }
        })
        .catch(error => {
          console.error(error.response)
          if (error.response) {
            self.dispatch('showSnackbar', error.response.message)
          } else {
            self.dispatch('showSnackbar', this.$t('errors.server_error'))
          }
        })
    },

    sendReaded ({ state, commit, rootState }) {
      const self = this

      axios({
        method: 'post',
        url: config.API_HOST + '/kassa/check_news',
        data: qs.stringify({
          user_id: rootState.user.id,
          city_id: rootState.user.city_id,
          token: rootState.user.token,
          news_readed: state.newsRead
        })
      })
        .then(response => {
          state.newsRead = []
        })
        .catch(error => {
          console.error(error.response)
          if (error.response && error.response.message) {
            self.dispatch('showSnackbar', error.response.message)
          } else {
            self.dispatch('showSnackbar', this.$t('errors.server_error'))
          }
        })
    }
  }
}
