import IIKO from './IIKO'
import IIKOErrors from './IIKOErrors'
import { i18n } from '@/i18n'

import { getInfo, sendBackend } from '@/functions/webservices/iiko'

export default class IIKOPlugin {
  getInfo (id) {
    return getInfo(id)
  }

  update (id) {
    return new Promise((resolve, reject) => {
      const url = '/iiko/updateInfoAboutPlugin'
      let data = {
        status: 'success',
        message: null
      }
      IIKO.getInfo().then(async response => {
        const result = response.data.result

        if (!result.idShop || result.idShop === 0 || result.idShop === '0') {
          data = {
            status: 'error',
            message: 'Invalid iiko id (0)'
          }
          return
        }

        const requestData = {
          shop_id: id,
          plugin_id: result.idShop
        }
        await sendBackend('post', url, requestData)
          .catch(error => {
            data = {
              status: 'error',
              message: error.response.data.message
            }
          })
        resolve(data)
      }).catch(error => {
        let message = ''
        if (!error.response) {
          message = IIKOErrors.NO_CONNECTION_ERRORS()[0]
        } else if (error.response.data && error.response.data.message) {
          message = error.response.data.message
        } else {
          message = i18n.t('errors.request_error_iiko')
        }

        data = {
          status: 'error',
          message: message
        }
        reject(data)
      })
    })
  }
}
