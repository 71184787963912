<template>
  <v-dialog :value="visible" persistent max-width="600px">
    <v-list two-line>
      <v-subheader class="justify-space-between">
        {{ $t('order_modal.courier_modal.title') }}
        <v-icon @click="$emit('hide')">mdi-close</v-icon>
      </v-subheader>
      <template v-for="(item, index) in couriers">
        <v-divider :key="'div-rider-' + item.id" v-if="index !== 0" :inset="true"></v-divider>
        <v-list-item
          :key="item.id"
          @click="selectCourier(item)">
          <v-list-item-icon>
            <v-icon v-if="item.id === 0">mdi-delete-forever</v-icon>
            <v-icon v-else>mdi-face</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-dialog>
</template>

<script>
import { Order } from '../../Models/Order'
import { mapMutations } from 'vuex'

export default {
  props: {
    order: Order,
    couriers: Array,
    visible: {
      type: Boolean,
      default: false
    }
  },
  name: 'CouriersModal',
  methods: {
    ...mapMutations([
      'updateOpenOrder'
    ]),
    selectCourier (courier) {
      this.$store.state.loader.text = this.$t('order_modal.loader.saving')
      this.$store.state.loading = true

      this.order.changeCourier(courier.id).then(response => {
        this.$props.order.courierID = courier.id
        this.$props.order.courier = courier
        this.updateOpenOrder({ fields: ['courierID', 'courier'], values: { courierID: courier.id, courier } })
        this.$emit('hide')
      }).catch(error => {
        let message = this.$t('errors.server_error')
        if (error.response) {
          if (error.response.status === 400) {
            message = error.response.data.message
          }
        }
        this.$store.dispatch('showSnackbar', message)
      }).finally(() => {
        this.$store.state.loading = false
      })
    }
  }
}
</script>
