<style lang="scss">
    .auto-height {
        height: auto;

        .list__tile {
            height: auto;
            padding: 1rem;

            .article-shorttext {
                padding-right: 2rem;
            }
        }
    }
</style>

<template>
    <v-dialog
      :value="dialog" @input="$emit('input', $event.target.value)"
      fullscreen
      transition="none"
      :overlay=false>
        <v-card>
            <v-toolbar style="flex: 0 0 auto;" dark class="indigo darken-1">
                <v-btn icon
                       :disabled="!$store.getters.canCloseNews"
                       @click.native="$store.dispatch('closeNews')" dark>
                    <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>Новости</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-layout row wrap fill-height>
                <v-flex xs12 sm10 offset-sm1>
                    <div class="loader-container" v-if="$store.state.news.loading">
                        <v-progress-circular indeterminate :size="50" color="secondary"></v-progress-circular>
                    </div>
                    <v-list v-if="news && news.length">
                        <template v-for="article in news">
                            <v-list-item class="auto-height" avatar :key="article.id" @click="openArticle(article)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ article.title }}</v-list-item-title>
                                    <div class="caption article-shorttext" v-html="article.short_text">Краткое описание отсутствует</div>
                                    <div v-if="article.full_text">
                                        <a href="javascript:void(0);" @click="openArticle(article)">Читать далее...</a>
                                    </div>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <div class="caption">{{ article.start_date }}</div>
                                    <v-icon v-if="!article.read">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon v-if="article.read" color="primary">mdi-checkbox-marked</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <template v-if="!article.full_text">
                                <v-list-item :key="article.id">
                                    <v-btn block
                                           color="green dark-1"
                                           dark
                                           :disabled="article.read"
                                           @click="read(article)">
                                        Прочитано &nbsp;
                                        <v-icon dark>mdi-eye</v-icon>
                                    </v-btn>
                                </v-list-item>
                            </template>
                            <v-divider :key="article.id"></v-divider>
                        </template>
                    </v-list>
                    <v-flex xs12 text-xs-center v-else>
                        <v-card>
                            <v-card-title primary-title>
                                <v-flex xs12>
                                    <div class="text-xs-center">
                                        <v-icon x-large class="mb-3">mdi-inbox</v-icon>
                                        <p>Новостей пока нет</p>
                                    </div>
                                </v-flex>
                            </v-card-title>
                        </v-card>
                    </v-flex>
                    <v-spacer></v-spacer>
                </v-flex>
            </v-layout>
        </v-card>

        <article-dialog :dialog="articleDialog" :article="articleDialog.article"></article-dialog>

    </v-dialog>
</template>

<script>
import ArticleDialog from '@/components/ArticleDialog'

export default {
  components: {
    'article-dialog': ArticleDialog
  },
  data: () => ({
    articleDialog: {
      show: false,
      article: null
    }
  }),
  props: ['dialog', 'news'],
  methods: {
    openArticle (article) {
      if (article.full_text && article.full_text.length) {
        this.articleDialog.show = true
        this.articleDialog.article = article

        return true
      }

      this.$store.dispatch('showSnackbar', 'Это полный текст статьи')
      return false
    },
    read (article) {
      this.$store.commit('readArticle', article)
    }
  }
}
</script>
