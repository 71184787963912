import { addShopToStopList, restoreShopFromStopList } from '../../functions/stopList/pickup'
import { getTime, getInfoAboutClosingShop, saveCookingTime } from '../../functions/times/cooking_time'

import { i18n } from '@/i18n'
import moment from 'moment'

export default {
  namespaced: true,

  state: () => ({
    loading: false,
    inactive: {
      status: false,
      inactiveTime: null
    },
    time: {
      cooking: 0,
      default: 0,
      minValue: 0,
      colors: {
        track_color: '#bdbdbd'
      },
      min: 0,
      max: 90,
      step: 5
    },
    update: {
      visible: false,
      form: {
        date: null,
        time: null
      }
    },
    stopList: {
      visible: false,
      modal: {
        visible: false,
        title: '',
        params: {}
      }
    }
  }),

  mutations: {
    changeLoading (state, val) {
      state.loading = val
    },
    changeInactiveStatus (state, val) {
      state.inactive.status = val
    },
    changeUpdateStatus (state, val) {
      state.update.visible = val
    },
    changeStopListStatus (state, val) {
      state.stopList.visible = val
    },
    changeStopListModalStatus (state, val) {
      state.stopList.modal.visible = val
    },
    clearStore (state) {
      state.time.cooking = 0
      state.inactive = {
        status: false,
        inactiveTime: null
      }
      state.stopList.visible = false
      state.update = {
        visible: false,
        form: {
          date: null,
          time: null
        }
      }
    },
    setStopList (state, val) {
      state.stopList = {
        ...state.stopList,
        ...val
      }
    },
    setStopListModal (state, val) {
      state.stopList.modal = {
        ...state.stopList.modal,
        ...val
      }
    },
    setUpdate (state, val) {
      state.update = {
        ...state.update,
        ...val
      }
    },
    setTime (state, val) {
      state.time = {
        ...state.time,
        ...val
      }
    },
    setDefaultTime (state) {
      state.update.form.time = moment().add(30, 'minutes').format('HH:mm')
    }
  },

  getters: {
    timeMarks (state) {
      const labels = []
      labels[state.time.min / 5] = `${state.time.min}`
      labels[state.time.cooking / 5] = i18n.t('time.delay_minutes', { time: state.time.cooking })
      labels[state.time.max / 5] = `${state.time.max}`

      return labels
    },
    min (state, getters, rootState) {
      return rootState.user.cooking_time
    },
    dates (state) {
      const now = moment().locale(i18n.locale)
      const addDay = moment(now).add(1, 'day')
      const add2Day = moment(now).add(2, 'day')
      const add3Day = moment(now).add(3, 'day')
      state.update.form.date = moment(now).format('YYYY-MM-DD')

      return [
        { text: i18n.t('today'), value: moment(now).format('YYYY-MM-DD') },
        { text: moment(addDay).format('D MMMM'), value: moment(addDay).format('YYYY-MM-DD') },
        { text: moment(add2Day).format('D MMMM'), value: moment(add2Day).format('YYYY-MM-DD') },
        { text: moment(add3Day).format('D MMMM'), value: moment(add3Day).format('YYYY-MM-DD') }
      ]
    }
  },

  actions: {
    addShopToStopList,
    restoreShopFromStopList,
    getInfoAboutClosingShop,
    saveCookingTime,
    getTime,
    getCustomTime ({ state, commit, getters, dispatch, rootState }) {
      commit('changeLoading', true)

      dispatch('getTime', rootState.user.id).then(response => {
        if (response.data.time !== null) {
          commit('setTime', { cooking: response.data.time })
        } else {
          commit('setTime', { cooking: getters.min })
        }

        commit('setTime', { default: state.time.cooking })
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    save ({ state, dispatch, commit, rootState }) {
      commit('changeLoading', true)

      if (!state.update.form.date || !state.update.form.time) {
        dispatch('showSnackbar', i18n.t('data_not_all_provided'), { root: true })
        commit('changeLoading', false)
        return false
      }

      const data = {
        id: rootState.user.id,
        date: state.update.form.date,
        time: state.update.form.time,
        minutes: state.time.cooking
      }

      dispatch('saveCookingTime', data).then(response => {
        dispatch('showSnackbar', i18n.t('text.data_updated'), { root: true })

        commit('setUpdate', {
          visible: false,
          form: {
            date: null,
            time: null
          }
        })
        commit('setTime', { default: state.time.cooking })
      }).catch(error => {
        dispatch('showSnackbar', error.response.data.message, { root: true })
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    getInfoAboutClosing ({ state, commit, dispatch, rootState }) {
      commit('changeLoading', true)

      dispatch('getInfoAboutClosingShop', rootState.user.id).then(response => {
        if (response.data.inactiveTime !== undefined) {
          commit('changeInactiveStatus', true)
          state.inactive.inactiveTime = response.data.inactiveTime
        }
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    shopToStopList ({ commit, rootState }) {
      commit('setStopListModal', {
        visible: true,
        title: rootState.user.name,
        params: { shop: rootState.user.id }
      })
    },
    restore ({ rootState, commit, dispatch }) {
      commit('changeLoading', true)

      dispatch('restoreShopFromStopList', rootState.user.id).then(response => {
        dispatch('showSnackbar', i18n.t('stop_list.success.shop_restore_from_stop_list'), { root: true })

        commit('changeInactiveStatus', false)
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    updatePageAfterStopShop ({ state, commit, dispatch }) {
      commit('clearStore')
      dispatch('getInfoAboutClosing')
      commit('changeInactiveStatus', true)
      commit('setTime', { cooking: state.time.default })
    },
    updateValue ({ state, commit, rootState }, val) {
      if (val <= state.time.minValue) {
        val = state.time.minValue
      }

      commit('setTime', { cooking: val })
    },

    saveStopList ({ state, rootState, dispatch, commit }, data) {
      commit('changeLoading', true)

      const request = {
        shop: rootState.user.id,
        comment: data.reason,
        date: data.period
      }

      if (!request.shop || !request.date || !request.comment) {
        dispatch('showSnackbar', i18n.t('data_not_all_provided'), { root: true })
        commit('changeLoading', false)
        return false
      }

      if (data.period === 'enter') {
        request.date = data.enterPeriod.day * 24 * 60
        request.date += data.enterPeriod.hour * 60
        request.date += data.enterPeriod.minute
      }

      dispatch('addShopToStopList', request).then(response => {
        dispatch('showSnackbar', i18n.t('stop_list.success.shop_added_to_stop_list'), { root: true })

        commit('changeStopListModalStatus', false)
        dispatch('updatePageAfterStopShop')
      }).catch(error => {
        dispatch('showSnackbar', error.response?.data?.message, { root: true })
      }).finally(() => {
        commit('changeLoading', false)
      })
    }
  }
}
