<template>
  <v-container>
    <v-row>
      <v-col v-if="loading && devices.length === 0">
        <v-alert text elevation="2" dense>{{ $t('loader.loading_data') }}</v-alert>
      </v-col>

      <v-col v-else-if="devices.length === 0">
        <v-alert text elevation="2" dense>{{ $t('device.no_data_available') }}</v-alert>
      </v-col>

      <div v-else class="device-box">
        <v-slide-group class="py-4" show-arrows>
          <template v-slot:next>
            <v-icon x-large>mdi-arrow-right-bold-circle</v-icon>
          </template>
          <template v-slot:prev>
            <v-icon x-large>mdi-arrow-left-bold-circle</v-icon>
          </template>
          <v-slide-item class="device-slider-item" v-for="(device) in devices" :key="device.id">
            <div class="device d-flex justify-start align-center flex-column mx-3"
              @click="showInputAuthCode(device)">
              <div class="device-icon pb-2">
                <!--            <v-img-->
                <!--              :lazy-src="types[device.type_slug].icon"-->
                <!--              max-height="100"-->
                <!--              max-width="100"-->
                <!--              :src="types[device.type_slug].icon"-->
                <!--            ></v-img>-->
                <v-icon x-large>
                  mdi-desktop-mac
                </v-icon>
              </div>

              <div class="device-desc text-center text-wrap">
                <div class="device-name">
                  <span class="font-weight-bold">{{ device.stations[0].name }}</span>
                </div>
                <div class="device-name">{{ $t('device.' + device.type_slug) }}</div>
              </div>
            </div>
          </v-slide-item>
        </v-slide-group>
      </div>
    </v-row>

    <v-row v-if="authDevices.length !== 0">
      <v-col cols="12">
        <AuthDevices :items="authDevices" />
      </v-col>
    </v-row>

    <input-code-modal />

  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import InputCodeModal from './InputCodeModal'
import AuthDevices from './AuthDevices'

export default {
  name: 'Devices',
  components: {
    'input-code-modal': InputCodeModal,
    AuthDevices
  },
  props: {
    tab: String
  },
  computed: {
    ...mapState('device', {
      loading: state => state.loading,
      devices: state => state.devices,
      authDevices: state => state.authDevices,
      types: state => state.types
    })
  },
  methods: {
    ...mapMutations('device', [
      'changeVisibleModal',
      'setCodeModal',
      'changeVisibleCodeModal'
    ]),
    ...mapActions('device', [
      'getDevices',
      'getDeviceTypes',
      'getAuthDevices',
      'logout'
    ]),
    addDevice () {
      this.changeVisibleModal(true)
    },
    showInputAuthCode (device) {
      this.setCodeModal({ id: device.id })
      this.changeVisibleCodeModal(true)
    }
  },
  watch: {
    tab (val) {
      if (val === '') {
        this.getDevices()
        this.getDeviceTypes()
        this.getAuthDevices()
      }
    }
  },
  created () {
    this.getDevices()
    this.getDeviceTypes()
    this.getAuthDevices()
  }
}
</script>

<style scoped>
  .device-box {
    width: 100%;
  }

  .device {
    cursor: pointer;
    width: 120px;
  }

  .device-icon {
    max-width: 100px;
    max-height: 100px;
  }
</style>
