<template>
  <v-dialog
    :value="visible" @input="$emit('close')"
    fullscreen
    return-value="true"
    persistent
    :overlay=false>
    <v-card>
      <v-toolbar style="flex: 0 0 auto;" dark class="light-green darken-1" flat>
        <v-btn icon @click.native="$emit('close')" dark>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('order_history.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-layout row wrap>
          <v-flex xs12>
            <v-card flat v-if="loading">
              <v-card-title primary-title>
                <v-progress-linear v-bind:indeterminate="true"></v-progress-linear>
                <p class="text-xs-center">{{ $t('order_history.loading') }}</p>
              </v-card-title>
            </v-card>
            <v-list two-line v-if="!loading">
              <v-subheader>{{ $t('order_history.subtitle') }}</v-subheader>
              <template>
                <div class="order-history-list" ref="historyList">
                  <div v-for="(history, index) in list" :key="'history-' + index" class="unselectable">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ getTime(history.time) }}</v-list-item-title>
                        <div class="caption">{{ history.text }}</div>
                        <v-list-item-title>{{ statusNames[history.status] }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text>{{ getDate(history.time) }}</v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="index + 1 < list.length" :key="index"></v-divider>
                  </div>
                </div>
              </template>
            </v-list>
            <v-spacer></v-spacer>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderHistoryModal',
  props: {
    visible: Boolean,
    loading: Boolean,
    order: Object,
    list: Array
  },
  computed: {
    ...mapGetters([
      'statusNames'
    ])
  },
  methods: {
    getTime (datetime) {
      return this.$d(moment(datetime), 'time')
    },
    getDate (datetime) {
      return this.$d(moment(datetime), 'full')
    },
    mouseDownHandler (e) {
      this.pos = {
        left: this.$refs.historyList.scrollLeft,
        top: this.$refs.historyList.scrollTop,
        x: e.clientX,
        y: e.clientY
      }

      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('mouseup', this.mouseUpHandler)
    },
    mouseMoveHandler (e) {
      this.drag = true
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y

      this.$refs.historyList.scrollTop = this.pos.top - dy
      this.$refs.historyList.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler (e) {
      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
      setTimeout(() => {
        this.drag = false
      }, 30)
    }
  },
  watch: {
    visible (val) {
      if (val) {
        setTimeout(() => {
          if (this.$refs.historyList) {
            this.$refs.historyList.addEventListener('mousedown', this.mouseDownHandler)
          }
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.order-history-list {
  height: calc(100vh - 137px);
  overflow: auto;
}
</style>
