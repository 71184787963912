import axios from 'axios'
import settings from '@/config/settings'

import { logger } from '@/helpers'

const TIMEOUT = 600000

export default class IIKO {
  static sendToIIKO (method, url, data, headers = {}) {
    logger('log', 'request to iiko -> ', { method, url, data, headers })
    return axios({ method, url: settings.IIKO_HOST + url, data, timeout: TIMEOUT, headers })
  }

  static getInfo () {
    return axios({ method: 'get', url: settings.IIKO_HOST + '/check', timeout: TIMEOUT })
  }
}
