<template>
    <base-template-modal v-bind="this.$props" :type="type" :loading="loading">
        <v-container>
            <v-text-field
                v-for="(field, index) in fields"
                :key="'prompt-modal-field-' + index"
                v-model="field.value"
                :label="field.label"
                required
            ></v-text-field>
        </v-container>

        <template #buttons>
            <v-btn
                class="test"
                v-for="(button, key) in buttons"
                :key="'prompt_modal_btn_' + key"
                :color="button.color"
                @click="handleButton(key)"
                :loading="loading"
            >{{ button.text }}</v-btn>
        </template>
    </base-template-modal>
</template>

<script>
import BaseTemplateModal from './BaseTemplateModal.vue'
import { mapActions } from 'vuex'

export default {
  name: 'PromptModal',
  components: { BaseTemplateModal },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: String,
    fields: {
      type: Array,
      default: () => [{ slug: '', value: null, label: '' }],
      required: true
    },
    alert: Object,
    buttons: Object
  },
  data: () => {
    return {
      type: 'prompt',
      loading: false
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),
    async handleButton (type) {
      this.loading = true

      const errors = []
      const args = this.fields.reduce((out, field) => {
        if (field.value) {
          out[field.slug] = field.value
        }
        if (field.required && !field.value) {
          errors.push(field.label + ' не указан')
        }

        return out
      }, {})

      if (errors.length > 0) {
        errors.forEach(error => {
          this.showSnackbar(error)
        })
        return
      }

      if (typeof this.buttons[type].action === 'function') await this.buttons[type].action(args)

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
