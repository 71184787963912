import { i18n } from '@/i18n'

export default class IIKOErrors {
  static NO_CONNECTION_ERRORS () {
    return [
      i18n.t('errors.iiko.iiko_not_running'),
      i18n.t('errors.iiko.plugin_not_installed_in_iiko'),
      i18n.t('errors.iiko.antivirus_blocks_transfer_orders'),
      i18n.t('errors.iiko.network_setup_error')
    ]
  }

  static noConnectionError () {
    return {
      title: i18n.t('errors.request_error_iiko'),
      color: 'red',
      text: '',
      list: this.NO_CONNECTION_ERRORS()
    }
  }

  static responseError (error) {
    let errors = this.NO_CONNECTION_ERRORS()
    if (error.response) {
      errors = [errors.response.data.message]
    }

    return errors
  }
}
