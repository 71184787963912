<template>
    <v-container fluid>
        <v-layout row wrap>
            <v-flex xs12>
              <orders-table height="calc(100vh - 48px)" type="orders" :orders="orders" :with-filters="true"></orders-table>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import OrdersTable from './OrdersTable'

export default {
  components: {
    OrdersTable
  },
  computed: {
    ...mapGetters({
      orders: 'getActiveOrders'
    })
  },
  methods: {
    ...mapActions([
      'loadOrders'
    ]),
    ...mapMutations([
      'setBadges'
    ])
  },
  mounted () {
    this.setBadges({ type: 'active', value: this.getActiveOrders?.length ?? 0 })
    this.loadOrders()
  }
}
</script>
