<template>
  <div class="confirm-order-modal">
    <v-dialog
      @input="close"
      :value="visible"
      persistent
      max-width="500px">
      <v-card>
        <v-card-title class="text-h5">{{ $t('confirm_order.confirmation.title') }}</v-card-title>
        <v-card-text>
          <div v-if="code" class="text-center text-h3" style="letter-spacing: 10px !important;">
            {{ code }}
          </div>

          <div v-if="message && message.text && message.type">
            <v-alert outlined :type="message.type">
              {{ message.text }}
            </v-alert>
          </div>

          <div>
            <v-alert
              outlined
              color="grey darken-2">
              {{ $t('confirm_order.confirmation.desc') }}
            </v-alert>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close()" :loading="loading">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ConfirmOrderModal',
  props: {
    visible: Boolean,
    loading: Boolean,
    code: String,
    message: Object
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
