<template>
    <v-container grid-list-xs>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card flat>
                    <v-card-text>
                      <v-layout row>
                        <v-flex xs12>
                          <v-alert
                            :value="true"
                            color="info"
                            icon="mdi-information"
                            outlined>
                            {{ $t('payment_report.desc') }}
                          </v-alert>
                        </v-flex>
                      </v-layout>
                        <v-layout row>
                            <v-flex xs6>
                                <v-dialog
                                  persistent
                                  v-model="modalStart"
                                  width="290px">
                                  <template v-slot:activator="{on:date}">
                                    <v-text-field
                                      v-on="{...date}"
                                      :label="$t('payment_report.from_date')"
                                      v-model="dateFormattedStart"
                                      prepend-icon="mdi-calendar"
                                      readonly></v-text-field>
                                  </template>
                                    <v-date-picker v-model="date" @input="dateFormattedStart = filterByDate($event)" :locale="$i18n.locale"
                                                   scrollable actions>
                                        <template>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="($event) => { modalStart = false;  }">{{ $t('cancel') }}</v-btn>
                                                <v-btn text color="primary" @click="($event) => { modalStart = false; }">{{ $t('ok') }}</v-btn>
                                            </v-card-actions>
                                        </template>
                                    </v-date-picker>
                                </v-dialog>
                            </v-flex>
                          <v-flex xs6>
                            <v-dialog
                              persistent
                              v-model="modalEnd"
                              width="290px">
                              <template v-slot:activator="{on:date}">
                                <v-text-field
                                  v-on="{...date}"
                                  :label="$t('payment_report.to_date')"
                                  v-model="dateFormattedEnd"
                                  prepend-icon="mdi-calendar"
                                  readonly></v-text-field>
                              </template>
                              <v-date-picker v-model="date" @input="dateFormattedEnd = filterByDate($event)" :locale="$i18n.locale"
                                             scrollable actions>
                                <template>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="($event) => { modalEnd = false; }">{{ $t('cancel') }}</v-btn>
                                    <v-btn text color="primary" @click="($event) => { modalEnd = false; }">{{ $t('ok') }}</v-btn>
                                  </v-card-actions>
                                </template>
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                        </v-layout>
                      <v-layout row>
                      <v-flex xs12>
                        <v-btn color="success" block dark large @click.native="getReport()">{{ $t('reports.generate_report') }}</v-btn>
                      </v-flex>
                      </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>

        </v-layout>

        <div class="text-xs-center">
                <v-dialog
                  v-model="dialog"
                  persistent
                  width="300">
                    <v-card
                      color="primary"
                      dark>
                        <v-card-text>
                            {{ $t('payment_report.loading') }}
                            <v-progress-linear
                              indeterminate
                              color="white"
                              class="mb-0"
                            ></v-progress-linear>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        <v-dialog
          v-model="dialogError.show"
          width="500">
            <v-card>
                <v-card-title
                  class="headline red lighten-2"
                  primary-title>
                    {{ $t('error') }}
                </v-card-title>

                <v-card-text>
                    {{ dialogError.message }}
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialogError.show = false">
                      {{ $t('well') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import qs from 'qs'
import config from '@/config/settings'
import IIKO from '@/Models/IIKO/IIKO'
import IIKOErrors from '@/Models/IIKO/IIKOErrors'

export default {
  data: () => ({
    loading: true,
    dialogError: {
      show: false,
      message: ''
    },
    dialog: false,
    dialogOrder: {},
    pagination: {},
    date: null,
    dateFormattedStart: moment().format('DD.MM.YYYY'),
    dateFormattedEnd: moment().format('DD.MM.YYYY'),
    modalStart: false,
    modalEnd: false
  }),
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    getReport () {
      this.dialog = true

      const user = (localStorage.getItem('user')) ? JSON.safeParse(localStorage.getItem('user')) : false

      axios({
        method: 'post',
        url: config.API_HOST + '/kassa/paymentType',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          user_id: user.id,
          token: user.token,
          cityId: user.city_id,
          dateStart: this.dateFormattedStart,
          dateEnd: this.dateFormattedEnd
        })
      }).then(response => {
        IIKO.sendToIIKO('post', '/printxml', {
          XmlMarkup: response.data
        }).catch(error => {
          if (!error.response) {
            const errorData = IIKOErrors.noConnectionError()
            this.$store.state.modal.diagnostics = true
            this.$store.dispatch('showModalWorker', errorData)
          }
        }).finally(() => {
          this.dialog = false
        })
      }).catch(() => {
        this.dialogError.message = this.$t('errors.one_more_devices')
        this.dialogError.show = true
      })
    },
    filterByDate (date) {
      return this.formatDate(date)
    }
  },
  mounted () {
    this.loading = false
  }
}
</script>
