import Vue from 'vue'
import Router from 'vue-router'

import Login from '@/components/Login'
import OrdersList from '@/components/OrdersList'
import OrdersListPreorder from '@/components/OrdersListPreorder'
import OrdersListClosed from '@/components/OrdersListClosed'
import PaymentReport from '@/components/Reports/PaymentReport'
import Iiko43Report from '@/components/Reports/Iiko43Report'
import Reports from '@/components/Reports/Reports'
import Search from '@/components/Search'
import { i18n, loadLanguageAsync } from '@/i18n'
import store from '@/store'
import TransferringOrdersToIikoReport from '@/components/Reports/TransferringOrdersToIikoReport.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/:locale',
      redirect: 'login',
      component: {
        template: '<router-view></router-view>'
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login
        },
        {
          path: 'orders',
          name: 'orders',
          component: OrdersList
        },
        {
          path: 'preorders',
          name: 'preorders',
          component: OrdersListPreorder
        },
        {
          path: 'closed',
          name: 'closed',
          component: OrdersListClosed
        },
        {
          path: 'reports',
          name: 'reports',
          component: Reports
        },
        {
          path: 'paymentReport',
          name: 'paymentReport',
          component: PaymentReport
        },
        {
          path: 'iiko43Report',
          name: 'iiko43Report',
          component: Iiko43Report
        },
        {
          path: 'transferringOrdersToIikoReport',
          name: 'transferringOrdersToIikoReport',
          component: TransferringOrdersToIikoReport
        },
        {
          path: 'search',
          name: 'search',
          component: Search
        }
      ]
    }
  ]
})

router.replace(`/${store.getters.getLocale || i18n.locale}/login`)

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    loadLanguageAsync(to.params.locale).then(() => next())
  }
})

export default router
