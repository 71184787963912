import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/ru'

import '@/helpers'
import { mergeDeep } from '@/helpers/merge'

import settings from '@/config/settings'

import router from '@/router'

import { i18n } from '@/i18n'

import ordersStore from './orders'
import timesStore from './times'
import stopList from './stopList'
import newsStore from './news'
import deviceStore from './devices'
import modalsStore from './modals'

import IIKOPlugin from '../Models/IIKO/IIKOPlugin'
import WebServices from '../webservices/main'

Vue.use(Vuex)
moment.locale(i18n.locale)

const user = (localStorage.getItem('user')) ? JSON.safeParse(localStorage.getItem('user')) : false

const store = {
  modules: {
    news: newsStore,
    times: timesStore,
    stop_list: stopList,
    device: deviceStore,
    modals: modalsStore
  },
  state: {
    eventBus: new Vue(),
    user: {
      id: user ? user.id : '',
      full_name: user ? user.full_name : '',
      login: user ? user.login : '',
      password: '',
      logged: false,
      token: user ? user.token : '',
      kassir_can_stop_pickup: false,
      kassir_can_stop_delivery: false
    },
    settings: settings,
    report: {
      data: []
    },
    errors: [],
    snackbar: {
      success: false,
      type: '',
      show: false,
      message: ''
    },
    bar: {
      badges: {
        active: 0,
        pre: 0,
        closed: 0
      },
      loading: false
    },
    page: null,
    worker: null,
    centrifuge: null,
    firebase: null,
    dialog: {
      show: false,
      header: '',
      text: '',
      callback: '',
      btn: {
        text: 'ok',
        color: 'green darken-2'
      }
    },
    modal: {
      show: false,
      text: '',
      color: '',
      title: '',
      list: null,
      diagnostics: false
    },
    loading: false,
    loader: {
      text: ''
    }
  },
  getters: {
    eventBus (state) {
      return state.eventBus ?? new Vue()
    },

    user (state) {
      return state.user ?? null
    },

    page (state) {
      return state.page
    },

    badges (state) {
      return state.bar.badges
    },

    getReport (state) {
      return state.report.data ? state.report.data : []
    },

    isLogged (state) {
      return state.user.logged
    },

    getErrors: (state) => {
      return state.errors
    },

    gotToken (state) {
      return !!(state.user.token && state.user.id && state.user.login)
    },

    getLocale () {
      return localStorage.getItem('locale')
    },

    getAppVersion () {
      const localVersion = localStorage.getItem('version')
      return localVersion ?? settings.USER_VERSION
    },
    getCentrifuge (state) {
      return state.centrifuge ?? null
    },
    getWorker (state) {
      return state.worker ?? null
    },
    getFirebase (state) {
      return state.firebase ?? null
    }
  },

  mutations: {
    setCentrifuge (state, val) {
      state.centrifuge = val
    },
    setWorker (state, val) {
      state.worker = val
    },
    setFirebase (state, val) {
      state.firebase = val
    },
    setLocale (state, locale) {
      localStorage.setItem('locale', locale)
    },
    setBadges (state, { type, value }) {
      state.bar.badges[type] = value
    }
  },

  actions: {
    clearErrors ({ state }) {
      state.errors = []
    },

    addError ({ state }, error) {
      state.errors.push(error)
    },

    setReport ({ state }, data) {
      state.report.data = {
        products: data.Products,
        payments: data.Payments
      }
    },

    clearReportData ({ state }) {
      state.report.data = []
    },

    postMessageWorker ({ state }, data) {
      state.worker.postMessage(data)
    },

    showSnackbar ({ state }, params) {
      if (typeof params === 'object') {
        if (typeof params.message === 'object') {
          let message = '<ul style="list-style-type: none">'
          params.message.forEach(msg => {
            message += '<li>' + msg + '</li>'
          })
          message = '</ul>'

          params.message = message
        }
      }

      state.snackbar = {
        ...state.snackbar,
        ...{ type: '' },
        ...(typeof params === 'string' ? { message: params } : params),
        show: true
      }
    },

    setLocale (context, locale) {
      localStorage.setItem('locale', locale)
    },

    showModalWorker ({ state }, data) {
      state.modal.text = data.text
      state.modal.color = data.color
      state.modal.title = data.title
      state.modal.list = data.list !== undefined ? data.list : null
      state.modal.show = true
    },

    showOrder (context, order) {
      this.state.orderDialog.show = true
      this.state.orderDialog.order = order
    },

    showOrderDialog ({ state }, order) {
      state.orderDialog.show = true
      state.orderDialog.order = order
    },

    showConfirmDialog ({ state }, settings) {
      state.dialog.show = true
      state.dialog.header = settings.header
      state.dialog.text = settings.text
      state.dialog.callback = settings.callback
      state.dialog.btn.text = settings?.btn_text ?? i18n.t('ok')
      state.dialog.btn.color = settings?.btn_color ?? 'green darken-2'

      return settings.callback
    },

    getInfoAboutPluginIntoWorker ({ state }) {
      state.worker.postMessage({ type: 'infoAboutPlugin', url: settings.API_HOST, shop_id: state.user.id })
    },

    getInfoAboutPlugin ({ state }) {
      const iikoPlugin = new IIKOPlugin()

      return iikoPlugin.update(state.user.id)
    },

    login ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: settings.API_HOST + '/kassa/auth',
          data: {
            login: state.user.login,
            password: state.user.password
          }
        }).then(response => {
          const data = response.data
          dispatch('fetchLoginData', data)

          const storageUser = {
            id: data.id,
            login: data.login,
            full_name: data.name + ', ' + data.city_address,
            brand: data?.brand_data?.name ?? '',
            token: data.token,
            isSelectCourier: data.isSelectCourier,
            isApiConnectorSushiMarket: data.isApiConnectorSushiMarket,
            city_id: data.city_id,
            city_name: data.city.City,
            name: data.name,
            address: data.city_address,
            time_zone: data.TimeZone,
            websocket_token: data.websocket_token,
            cooking_time: data.cooking_time
          }

          localStorage.setItem('user', JSON.stringify(storageUser))

          resolve(response.data)
        }).catch(error => {
          if (error.response === undefined) {
            dispatch('showSnackbar', i18n.t('errors.no_connection'))
          } else {
            if (error.response.status >= 400 && error.response.status < 500) {
              dispatch('showSnackbar', error.response.data.message)
            } else {
              dispatch('showSnackbar', i18n.t('errors.server_error'))
            }
          }

          reject(error)
        })
      })
    },
    loginByToken ({ dispatch, state }) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: settings.API_HOST + '/kassa/authByToken',
          headers: { Authorization: 'Bearer ' + state.user.token }
        }).then(response => {
          const data = response.data

          dispatch('fetchLoginData', data)

          resolve(response.data)
        }).catch(error => {
          if (error.response === undefined) {
            dispatch('showSnackbar', i18n.t('errors.no_connection'))
          } else {
            if (error.response.status === 400 && error.response.status < 500) {
              dispatch('showSnackbar', error.response.data.message)
            } else {
              dispatch('showSnackbar', i18n.t('errors.server_error'))
            }
          }

          dispatch('logout')

          reject(error)
        })
      })
    },

    /**
     * Update state with user data
     * @param commit
     * @param dispatch
     * @param state
     * @param data
     */
    fetchLoginData ({ commit, dispatch, state }, data) {
      state.user.id = data.id
      state.user.login = data.login
      state.user.brand = data?.brand_data?.name ?? ''
      state.user.brand_slug = data?.brand_data?.slug ?? null
      state.user.full_name = data.name + ', ' + data.city_address
      state.user.logged = true
      state.user.isSelectCourier = data.isSelectCourier
      state.user.isApiConnectorSushiMarket = data.isApiConnectorSushiMarket
      state.user.sendIIKO = data.sendIIKO
      state.user.city_id = data.city_id
      state.user.city_name = data.city.City
      state.user.name = data.name
      state.user.address = data.city_address
      state.user.time_zone = data.TimeZone
      state.user.websocket_token = data.websocket_token
      state.user.cooking_time = data.cooking_time
      state.user.type = data.type
      state.user.kassir_can_stop_pickup = data.kassir_can_stop_pickup
      state.user.kassir_can_stop_delivery = data.kassir_can_stop_delivery
      state.user.kassir_can_change_cooking_time = data.kassir_can_change_cooking_time
      state.user.kassir_can_change_delivery_time = data.kassir_can_change_delivery_time
      state.user.cash_registers = data.cash_registers

      if (state.news && data.news) {
        dispatch('pushNews', data.news)
      }

      if (data.status_list) {
        dispatch('pushOrderStatuses', data.status_list)
      } else {
        dispatch('loadOrderStatuses')
      }

      if (data.token) {
        state.user.token = data.token
        localStorage.setItem('token', data.token)
      }
      state.user.password = ''
      axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token')
    },

    logout ({ state, dispatch }) {
      state.loading = true
      axios.post(settings.API_HOST + '/kassa/logout').then(async response => {
        localStorage.setItem('user', '')
        localStorage.setItem('token', '')
        state.user = {
          id: '',
          brand: '',
          full_name: '',
          login: '',
          password: '',
          logged: false,
          token: '',
          isSelectCourier: false,
          isApiConnectorSushiMarket: false,
          sendIIKO: false,
          city_id: null,
          city_name: '',
          name: '',
          address: '',
          time_zone: 0,
          websocket_token: ''
        }

        await dispatch('webServicesDisconnect')

        router.replace({ path: 'login', redirect: '' })
      }).finally(() => {
        state.loading = false
      })
    },

    equalsAppVersion ({ getters }) {
      const version = getters.getAppVersion
      axios({
        method: 'get',
        url: settings.API_HOST + '/equalsVersion/' + version
      }).then(response => {
        if (!response.data.equals) {
          localStorage.setItem('version', response.data.version)
          location.reload(true)
        }
      })
    },

    getInformationAboutProgram ({ state }) {
      const iikoPlugin = new IIKOPlugin()
      return iikoPlugin.getInfo(state.user.id)
    },

    // Вызывается после авторизации
    setIntervals ({ dispatch, state, commit }) {
      setInterval(() => {
        dispatch('loadOrders')
      }, settings.ORDERS_UPDATE_TIME * 1000)

      setInterval(() => {
        dispatch('equalsAppVersion')
      }, settings.VERSION_UPDATE_TIME * 1000)

      setInterval(() => {
        const order = state.orderDialog.order.id
        if (order) {
          commit('updateOpenOrder', { fields: ['courier_service_id', 'skip_confirmation', 'courier', 'payment_types'] })
        }
      }, settings.ORDER_UPDATE_TIME * 1000)

      if (settings.AUTO_UPDATE_INFO_PLUGIN) {
        setInterval(() => {
          dispatch('getInfoAboutPluginIntoWorker')
        }, 3600000)
      }

      /*
       setInterval(function () {
       self.$store.dispatch('checkApiConnector')
       }, 4000)
       */
    },

    async webServicesDisconnect ({ commit }) {
      await new WebServices().disconnect()

      commit('setWorker', null)
      commit('setCentrifuge', null)
    }
  }
}

const mergedStore = mergeDeep(store, [ordersStore])

export default new Vuex.Store(mergedStore)
