import axios from 'axios'
import settings from '@/config/settings'

const API_HOST = settings.API_HOST + '/kassa/'

export function sendBackend (channel, id, data, token) {
  return axios.post(API_HOST + 'return_response', {
    channel: channel,
    data: JSON.stringify(data),
    user_id: id,
    token: token
  })
}
