import cookingTime from './cookingTime'
import deliveryTime from './deliveryTime'

export default {
  namespaced: true,

  modules: {
    delivery_time: deliveryTime,
    cooking_time: cookingTime
  },
  
  state: {
    loading: false
  },

  getters: {

  }
}
