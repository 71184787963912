const API_HOST = '/kassa/times/cooking-time/'

export function getInfoAboutClosingShop (context, id) {
  return this._vm.axios.get(API_HOST + 'get-info-about-closing/' + id)
}

export function saveCookingTime (context, data) {
  return this._vm.axios.post(API_HOST + 'save', data)
}

export function getTime (context, id) {
  return this._vm.axios.get(API_HOST + 'get-time/' + id)
}
