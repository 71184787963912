<template>
    <base-template-modal v-bind="this.$props" :type="type" :loading="loading">
        <div class="text-center text-h6 mb-4" v-html="text"></div>

        <template #buttons>
            <v-btn
                v-for="(button, key) in buttons"
                :key="'base_modal_btn_' + key"
                :color="button.color"
                @click="handleButton(key)"
                :loading="loading"
            >{{ button.text }}</v-btn>
        </template>
    </base-template-modal>
</template>

<script>
import BaseTemplateModal from './BaseTemplateModal.vue'

export default {
  name: 'ConfirmModal',
  components: { BaseTemplateModal },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: String,
    text: String,
    alert: Object,
    buttons: Object
  },
  data: () => {
    return {
      type: 'confirm',
      loading: false
    }
  },
  methods: {
    async handleButton (type) {
      this.loading = true

      if (typeof this.buttons[type].action === 'function') await this.buttons[type].action()

      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
