<template>
  <v-menu offset-y>
    <template v-slot:activator="{on: menu}">
      <v-btn small v-on="{...menu}" class="ml-2">
        <span class="locale">{{ $t(`languages.${locale}`) }}</span>
      </v-btn>
    </template>
    <v-list>
      <div v-for="(locale, index) in locales" :key="locale">
        <v-list-item @click="switchLocale(locale)">
          <v-list-item-content>{{ $t(`languages.${locale}`) }}</v-list-item-content>
        </v-list-item>
        <v-divider v-if="index !== localesLen - 1"></v-divider>
      </div>
    </v-list>
  </v-menu>
</template>

<script>

import { mapMutations } from 'vuex'

export default {
  name: 'LocaleSwitcher',
  data () {
    return {
      locales: [],
      locale: 'en'
    }
  },
  computed: {
    localesLen () {
      return this.locales.length
    }
  },
  methods: {
    ...mapMutations([
      'setLocale'
    ]),
    switchLocale (locale) {
      if (this.locale === locale) {
        return
      }
      this.locale = locale
      const to = this.$router.resolve({ params: { locale } })
      this.setLocale(locale)
      this.$router.push(to.location)
    }
  },
  created () {
    this.locale = this.$i18n.locale
    this.locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
  }
}
</script>

<style scoped lang="scss">
  .locale {
    font-size: 15px;
  }
</style>
