const API_HOST = '/kassa/times/delivery-time/'

export function getTime (context, id) {
  return this._vm.axios.get(API_HOST + 'get-time/' + id)
}

export function getInfoAboutClosingZone (context, id) {
  return this._vm.axios.get(API_HOST + 'get-info-about-closing/' + id)
}

export function saveDeliveryTime (context, data) {
  return this._vm.axios.post(API_HOST + 'save', data)
}
