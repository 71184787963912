<template>
  <v-dialog
    :value="visible"
    @click:outside="close"
    @keydown.esc="close"
    persistent
    max-width="800px">
    <v-card >
      <v-card-title>
        <span class="text-h5">{{ $t('tools') }}</span>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item.name">
            {{ $t(item.name) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.name">
            <v-card flat>
              <v-card-text>
                <component :is="item.component"></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AboutProgram from '../Tools/AboutProgram'
import IIKOInfo from '../Tools/IIKOInfo'
import Devices from '../Tools/Devices/Devices'
import SelfTerminal from '../Tools/SelfTerminal.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  name: 'ToolsModal',
  components: {
    'about-program': AboutProgram,
    self_terminal: SelfTerminal,
    iiko: IIKOInfo,
    Devices
  },
  data () {
    return {
      tab: 'about_program'
    }
  },
  computed: {
    items () {
      return [
        {
          name: 'about_program',
          component: 'about-program'
        },
        {
          name: 'IIKO',
          component: 'iiko'
        },
        {
          name: 'devices',
          component: 'devices'
        },
        {
          name: 'self_terminal',
          component: 'self_terminal'
        }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
