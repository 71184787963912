<template>
  <v-dialog
    :value="visible"
    @click:outside="close"
    @keydown.esc="close"
    persistent
    max-width="40%">
    <v-card :loading="loading" :class="{disable: loading}">
      <v-card-title>
        <span class="text-h5">{{ $t('suspension') }}</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <span>{{ title }}</span>
              </v-col>

              <v-col cols="12">
                <span>{{ $t('stop_list.reason') }}</span>
              </v-col>

              <v-col cols="12">
                <v-btn
                  :disabled="loading"
                  v-for="(item, index) in reasons"
                  :key="item.value + index"
                  rounded
                  :class="[form.reason === item.value ? 'activeClass' : '']"
                  @click="form.reason = item.value"
                  style="margin: 0 5px 10px;">
                  {{ item.value }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <span>{{ $t('stop_list.date_to') }}</span>
              </v-col>

              <v-col cols="12">
                <v-btn
                  :disabled="loading"
                  v-for="(item, index) in periods"
                  :key="item.value + index"
                  rounded
                  :class="[form.period === item.value ? 'activeClass' : '']"
                  @click="form.period = item.value"
                  style="margin: 0 5px 10px;">
                  {{ item.title }}
                </v-btn>
              </v-col>

              <v-col :cols="12">
                <div v-if="form.period === 'enter'" class="d-flex justify-center">
                  <div class="enter-period">
                    <v-text-field
                      :disabled="loading"
                      hide-details
                      :class="['centered-input']"
                      v-model="enterPeriodValueDay"
                      @keypress="enterPeriodValid($event)"
                      ref="enterPeriodDay"
                      placeholder="0"
                      dense
                      solo
                    ></v-text-field>
                    <span>{{ $t('time.days_title') }}</span>
                  </div>
                  <div class="enter-period">
                    <v-text-field
                      :disabled="loading"
                      hide-details
                      :class="['centered-input']"
                      v-model="enterPeriodValueHour"
                      @keypress="enterPeriodValid($event)"
                      ref="enterPeriodHour"
                      placeholder="00"
                      dense
                      solo
                    ></v-text-field>
                    <span>{{ $t('time.hours_title') }}</span>
                  </div>
                  <div class="enter-period-separator">
                    <div>|</div>
                  </div>
                  <div class="enter-period">
                    <v-text-field
                      :disabled="loading"
                      hide-details
                      :class="['centered-input']"
                      v-model="enterPeriodValueMinute"
                      @keypress="enterPeriodValid($event)"
                      ref="enterPeriodMinute"
                      placeholder="00"
                      dense
                      solo
                    ></v-text-field>
                    <span>{{ $t('time.minutes_title') }}</span>
                  </div>
                </div>
              </v-col>

              <v-col v-if="form.enterPeriod.error && form.period === 'enter'" :cols="12" align="center">
                <span style="color: #fc2b2b">{{ $t('stop_list.errs.max_period') }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          @click="save">
          {{ $t('save') }}
        </v-btn>
        <v-btn
          style="color: white"
          color="blue"
          @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'StopListModal',
  props: {
    loading: Boolean,
    title: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    form: {
      period: '',
      reason: '',
      enterPeriod: {
        day: '',
        hour: '',
        minute: '',
        error: false
      }
    }
  }),
  computed: {
    enterPeriodValueMinute: {
      get () {
        return this.form.enterPeriod.minute
      },
      set (val) {
        val = Number(val)
        const newVal = this.enterPeriodInput(val, 'minute')
        this.$refs.enterPeriodMinute.lazyValue = newVal
        this.form.enterPeriod.minute = newVal
      }
    },
    enterPeriodValueHour: {
      get () {
        return this.form.enterPeriod.hour
      },
      set (val) {
        val = Number(val)
        const newVal = this.enterPeriodInput(val, 'hour')
        this.$refs.enterPeriodHour.lazyValue = newVal
        this.form.enterPeriod.hour = newVal
      }
    },
    enterPeriodValueDay: {
      get () {
        return this.form.enterPeriod.day
      },
      set (val) {
        val = Number(val)
        const newVal = this.enterPeriodInput(val, 'day')
        this.$refs.enterPeriodDay.lazyValue = newVal
        this.form.enterPeriod.day = newVal
      }
    },
    periods () {
      return [
        { value: 30, title: this.$t('stop_list.periods.half_hour') },
        { value: 60, title: this.$t('stop_list.periods.hour') },
        { value: 120, title: this.$t('stop_list.periods.two_hours') },
        { value: 'endDay', title: this.$t('stop_list.periods.till_day_end') },
        { value: 'enter', title: this.$t('select') }
      ]
    },
    reasons () {
      return [
        { value: this.$t('stop_list.reasons.running_late') },
        { value: this.$t('stop_list.reasons.event_or_emergency') },
        { value: this.$t('stop_list.reasons.other') }
      ]
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    enterPeriodValid (evt) {
      evt = (evt) || window.event
      const expect = evt.target.value.toString() + evt.key.toString()
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    enterPeriodInput (value, type) {
      this.form.enterPeriod.error = false
      if (type === 'minute') {
        this.form.enterPeriod.error = (this.form.enterPeriod.day > 5 && value !== '') ?? false
        return value >= 60 ? 59 : value
      } else if (type === 'hour') {
        this.form.enterPeriod.error = (this.form.enterPeriod.day > 5 && value !== '') ?? false
        return value >= 24 ? 23 : value
      } else if (type === 'day') {
        return value >= 7 ? 6 : value
      }
    },

    save () {
      this.$emit('save', this.form)
    }
  },
  watch: {
    visible (val) {
      if (val === true) {
        this.form = {
          period: '',
          reason: '',
          enterPeriod: {
            day: '',
            hour: '',
            minute: '',
            error: false
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .activeClass {
    color: #FFFFFF !important;
    background-color: #886ab5 !important;
    border-color: #886ab5 !important;
    outline: none !important;
  }

  .enter-period {
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px;
  }

  .enter-period-separator {
    display: flex;
    align-items: center;
    margin: 0 5px;
  }

  .enter-period-separator div {
    height: 38px;
  }

  .centered-input >>> input {
    text-align: center
  }

</style>
