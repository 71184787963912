<template>
  <v-dialog :value="visible" max-width="700px" @input="$emit('close')">
    <template>
      <v-card>
        <v-toolbar color="amber lighting-1">
          <span style="width: 100%; color: black" class="text-h4 text-center">{{ $t('warning') }}</span>
        </v-toolbar>
        <v-card-text class="text-h5" style="color: black">
          <div>{{ $t('text.order_IIKO_has_already_been_sent') }}</div>
          <span v-for="(order, index) in list" :key="order.iiko_number + index">
              <span v-if="index > 0">, </span><span>{{ order.iiko_number }}</span>
            </span>
          <div class="mt-7">
            {{ $t('text.resend_order') }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text outlined large :disabled="disableBtn" @click="$emit('send')">
            {{ $t('send') }} <span v-if="disableBtn">({{ disableSeconds }})</span>
          </v-btn>
          <v-btn text large outlined @click="$emit('close')">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: 'iikoOrderModal',
  props: {
    visible: Boolean,
    list: Array
  },
  data: () => {
    return {
      disableBtn: false,
      disableSeconds: 0,
      timerId: null,
      timeoutId: null
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.disableBtn = true
        this.disableSeconds = 5

        this.timerId = setInterval(() => {
          this.disableSeconds--
        }, 1000)

        this.timeoutId = setTimeout(() => {
          this.disableBtn = false
          clearInterval(this.timerId)
        }, 5000)
      } else {
        clearInterval(this.timerId)
        clearInterval(this.timeoutId)
      }
    }
  }
}
</script>

<style scoped>

</style>
