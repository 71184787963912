<template>
  <div class="preorder-list">
    <div class="filters">
        <div class="date-filter">
          <v-text-field
            @click="modal = true"
            :label="$t('form.filter_by_date')"
            v-model="dateFormatted"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>

          <v-btn color="primary" text small fab @click.native="resetOrders">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
    </div>
    <v-flex xs12>
      <orders-table
        height="calc(90vh - 107px)"
        type="pre_orders"
        :orders="orders"
        :date="dateFormatted"
        :loading="loading"/>
    </v-flex>

    <v-dialog
      persistent
      v-model="modal"
      width="290px">
      <v-date-picker
        :value="date"
        @input="val => selectedDate = val"
        :locale="$i18n.locale"
        :min="dateNow"
        scrollable actions>
        <template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">{{ $t('cancel') }}</v-btn>
            <v-btn text color="primary" @click="($event) => { date = selectedDate; modal = false; }">
              {{ $t('ok') }}
            </v-btn>
          </v-card-actions>
        </template>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import OrdersTable from './OrdersTable'
import Order from '../Models/Order'
import settings from '../config/settings'

export default {
  components: {
    OrdersTable
  },
  data: () => ({
    loading: false,
    dateNow: moment().format('YYYY-MM-DD'),
    selectedDate: null,
    date: null,
    dateFormatted: null,
    orders: [],
    modal: false,
    interval: null
  }),
  methods: {
    ...mapActions([
      'loadOrdersWithDate'
    ]),
    formatDate (date) {
      if (!date) {
        date = this.dateNow
      }

      return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    },
    filterByDate () {
      this.loading = true
      this.loadOrdersWithDate({ date: this.dateFormatted, pre: true }).then(response => {
        this.orders = response.data.orders.reduce((out, item) => {
          out.push(new Order(item))
          return out
        }, [])
      }).finally(() => {
        this.loading = false
      })
    },
    resetOrders () {
      this.date = this.dateNow
      this.filterByDate()
    }
  },
  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(val)
    },
    dateFormatted (val) {
      this.filterByDate()
    }
  },
  beforeRouteLeave (to, from, next) {
    if (from.name === 'preorders') {
      clearInterval(this.interval)
    }
    next()
  },
  created () {
    this.date = this.dateNow

    if (!this.interval) {
      this.interval = setInterval(() => {
        this.filterByDate()
      }, settings.ORDERS_UPDATE_TIME * 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.filters {
  padding: 1rem;
  height: 10vh;
  .date-filter {
    display: flex;
    .v-text-field {
      flex: 0 0 30%;
    }
  }
}
</style>
