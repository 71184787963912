<template>
  <v-dialog
    :value="visible"
    @click:outside="close"
    @keydown.esc="close"
    persistent
    max-width="700px">
    <v-card :loading="loading" :class="{disable: loading}">
      <v-card-title>
        <span class="text-h5">{{ $t('changing') }}</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <div v-if="!inactive.status">
          <span class="font-weight-bold">
              {{ $t('delivery_time') }} — {{ $t('time.delay_minutes', { time: time.delivery }) }}
          </span><br>
              <span v-if="user.kassir_can_change_delivery_time">
                  {{ $t('text.not_have_time_increase_delivery_time') }}
              </span>
            </div>

            <div v-else>
              <span class="font-weight-bold">
                  {{ $t('stop_list.sales_restores_in', { minutes: inactive.inactiveTime }) }}
              </span>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <time-slider
              :max="time.max"
              :min="time.min"
              :step="time.step"
              :min-value="time.minValue"
              :disabled="loading || inactive.status || !user.kassir_can_change_delivery_time"
              v-model="time.delivery"
            ></time-slider>
          </v-col>
        </v-row>

        <v-row class="my-5" align="center" v-if="update.visible">
          <v-col :cols="12" class="d-flex align-center">
            <span class="mr-2">{{ $t('valid_until') }}</span>

            <div>
              <v-select
                dense
                solo
                hide-details="auto"
                :placeholder="$t('select')"
                v-model="update.form.date"
                :items="dates"
              ></v-select>
            </div>

            <div class="d-inline ml-1">
              <input type="time" class="custom-time-picker" v-model="update.form.time">
            </div>
          </v-col>
        </v-row>

        <v-row v-if="stopList.visible && user.kassir_can_stop_delivery">
          <v-col :cols="12">
            <div id="warning-alert" style="width: 100%" class="d-flex align-items-center" @click="shopToStopList">
              <v-col :xs="2" :cols="1">
                <v-icon id="warning-lamp">mdi-lightbulb-on-outline</v-icon>
              </v-col>
              <v-col :xs="9" :cols="10" class="d-flex align-center">
                <span>{{ $t('text.if_cant_deliver_faster_better_close_zone') }}</span>
              </v-col>
              <v-col :cols="1" class="d-flex align-center justify-center">
                <v-icon>mdi-chevron-right</v-icon>
              </v-col>
            </div>
          </v-col>
        </v-row>

        <stop-list-modal
          :loading="loading"
          :title="stopList.modal.title"
          :visible="stopList.modal.visible"
          @save="saveStopList"
          @close="stopList.modal.visible = false">
        </stop-list-modal>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="mr-2" v-if="update.visible || inactive.status">
          <v-btn v-if="!inactive.status" @click="save" color="success">{{ $t('save') }}</v-btn>
          <v-btn v-else @click="restore(zone.id)" color="primary">{{ $t('stop_list.restore_sales') }}</v-btn>
        </div>
        <v-btn
          style="color: white"
          color="blue"
          @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CustomTimeSlider from './CustomTimeSlider'
import StopListPickupModal from '../StopList/Modals/StopListModal'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'DeliveryTimeModal',
  components: {
    'time-slider': CustomTimeSlider,
    'stop-list-modal': StopListPickupModal
  },
  computed: {
    ...mapState('times/delivery_time', {
      loading: state => state.loading,
      visible: state => state.modal.visible,
      zone: state => state.modal.zone,
      inactive: state => state.modal.inactive,
      time: state => state.modal.time,
      update: state => state.modal.update,
      stopList: state => state.modal.stopList
    }),
    ...mapGetters('times/delivery_time', [
      'min',
      'dates',
      'timeMarks'
    ]),
    ...mapGetters(['user'])
  },
  methods: {
    ...mapMutations('times/delivery_time', [
      'clear',
      'setTime',
      'setDefaultTime',
      'changeUpdateStatus',
      'changeStopListStatus'
    ]),
    ...mapActions('times/delivery_time', [
      'save',
      'getDeliveryZones',
      'shopToStopList',
      'saveStopList',
      'suspend',
      'restore'
    ]),
    close () {
      this.$emit('close')
    }
  },
  watch: {
    'update.visible' (val) {
      if (val) {
        this.setDefaultTime()
      }
    },
    visible (val) {
      if (val) {
        this.setTime({
          delivery: this.min,
          minValue: this.min
        })
      } else {
        if (this.inactive.status) {
          this.getDeliveryZones()
        }
        this.clear()
      }
    },
    'time.delivery' (val, old) {
      if (val === this.time.default) {
        this.changeUpdateStatus(false)
      } else if (old && val && val >= this.time.minValue) {
        this.changeUpdateStatus(true)
      }

      if (val >= this.time.max - 40) {
        this.changeStopListStatus(true)
      } else {
        this.changeStopListStatus(false)
      }
    },
    loading (val) {
      this.$emit('loading', val)
    }
  }
}
</script>

<style scoped>
  #warning-lamp {
    color: orange;
    font-size: 40px;
  }
  #warning-alert {
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .custom-time-picker {
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
</style>
