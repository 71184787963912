<template>
  <v-dialog
    :value="visible" @input="$emit('close')"
    fullscreen
    return-value="true"
    persistent
    :overlay=false>
    <v-card>
      <v-toolbar style="flex: 0 0 auto;" dark class="light-green darken-1">
        <v-btn icon @click.native="$emit('close')" dark>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('order_product_list.title') }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text class="fill-height">
        <v-layout row wrap fill-height>
          <v-flex xs12>
            <v-card flat v-if="loading">
              <v-card-title primary-title>
                <v-progress-linear v-bind:indeterminate="true"></v-progress-linear>
                <p class="text-xs-center">{{ $t('order_product_list.loading') }}</p>
              </v-card-title>
            </v-card>
            <v-list v-if="!loading">
              <v-subheader>{{ $t('order_product_list.subtitle') }}</v-subheader>
              <template>
                <div class="order-products-list" ref="productsList">
                  <v-list-item v-for="(product, index) in orderProducts" :key="'product-' + product.id + index" class="unselectable">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ product.name }} <span class="deep-orange--text">x {{ product.count }}</span>
                      </v-list-item-title>
                      <div class="caption">{{ getAmountFormat(product.price) }}</div>

                      <template v-if="modifiers[product.id] !== 0">
                        <v-list flat style="max-width: 600px;" class="ml-10">
                          <v-list-item v-for="(modifier, i) in modifiers[product.id]" :key="i">
                            <v-list-item-title>
                              {{ modifier.name }} <span class="deep-orange--text">x {{ modifier.count }}</span> ({{$t('in_each')}})
                            </v-list-item-title>
                            <div class="caption">{{ getAmountFormat(modifier.amount) }}</div>
                          </v-list-item>
                        </v-list>
                      </template>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <v-subheader/>
              <v-divider/>
            </v-list>
            <v-spacer/>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OrderProductsModal',
  props: {
    visible: Boolean,
    loading: Boolean,
    order: Object,
    list: Array
  },
  data: () => ({
    drag: false,
    pos: { top: 0, left: 0, x: 0, y: 0 }
  }),
  computed: {
    orderProducts () {
      return this.list
    },
    modifiers () {
      return this.list.reduce((out, item) => {
        out[item.id] = item.modifiers ?? []
        return out
      }, {})
    }
  },
  methods: {
    getAmountFormat (amount) {
      return Number(amount).toFixed(2)
    },
    mouseDownHandler (e) {
      this.pos = {
        left: this.$refs.productsList.scrollLeft,
        top: this.$refs.productsList.scrollTop,
        x: e.clientX,
        y: e.clientY
      }

      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('mouseup', this.mouseUpHandler)
    },
    mouseMoveHandler (e) {
      this.drag = true
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y

      this.$refs.productsList.scrollTop = this.pos.top - dy
      this.$refs.productsList.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler (e) {
      document.removeEventListener('mousemove', this.mouseMoveHandler)
      document.removeEventListener('mouseup', this.mouseUpHandler)
      setTimeout(() => {
        this.drag = false
      }, 30)
    }
  },
  watch: {
    visible (val) {
      if (val) {
        setTimeout(() => {
          if (this.$refs.productsList) {
            this.$refs.productsList.addEventListener('mousedown', this.mouseDownHandler)
          }
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.order-products-list {
  height: calc(100vh - 185px);
  overflow: auto;
}
</style>
