<template>
  <v-dialog
    :value="visible"
    @close.prevent="close"
    @click:outside="close"
    width="300">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        {{ $t('auth.authorize') }}
      </v-card-title>

      <v-card-text>
        <v-otp-input
          id="auth-code"
          ref="codeInput"
          :disabled="loading"
          v-model="code"
          :length="4"
          @finish="finishInput"
        ></v-otp-input>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'InputCodeModal',
  data: () => ({
    code: null
  }),
  computed: {
    ...mapState('device', {
      visible: state => state.codeModal.visible,
      loading: state => state.loading,
      id: state => state.codeModal.id
    })
  },
  methods: {
    ...mapMutations('device', [
      'changeVisibleCodeModal'
    ]),
    ...mapActions('device', [
      'authDevice'
    ]),
    close () {
      this.changeVisibleCodeModal(false)
    },
    finishInput (rsp) {
      this.authDevice({ id: this.id, code: this.code })
    }
  },
  watch: {
    visible (val) {
      if (val === true) {
        this.code = null
      }
    }
  }
}
</script>

<style scoped>

</style>
