<template>
    <div class="modals">
        <confirm-modal v-bind="confirm" @close="close('confirm')"/>
        <prompt-modal v-bind="prompt" @close="close('prompt')"/>
    </div>
</template>

<script>

import ConfirmModal from './ConfirmModal.vue'
import PromptModal from './PromptModal.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Modals',
  components: {
    ConfirmModal,
    PromptModal
  },
  computed: {
    ...mapState({
      modals: state => state.modals
    }),
    confirm () {
      return Object.assign(this.modals.confirm)
    },
    prompt () {
      return Object.assign(this.modals.prompt)
    }
  },
  methods: {
    ...mapMutations({
      setVisible: 'modals/clear'
    }),
    close (type) {
      this.clear({ type, value: false })
    }
  }
}
</script>

<style scoped>

</style>
