import store from '@/store'
import { i18n } from '@/i18n'
import IIKOErrors from '@/Models/IIKO/IIKOErrors'
import qs from 'qs'

import { logger } from '@/helpers'

export default class WorkerHandling {
  static successResponse (response) {
    logger('log', 'success response from ' + response.type, response)

    switch (response.type) {
      case 'report43':
        responseReport43(response.data)
        break
      case 'iikoOrder':
        store.state.orderDialog.order.iikoNumber = response.iikoNumber
        break
      case 'sendIIKO':
        responseSendIIKO(response.data)
        break
      case 'kassaSendIIKO':
        responseKassaSendIIKO(response.data)
        break
      case 'print':
        responsePrint(response.data)
        break
      case 'cancelIIKOOrder':
        responseCancelIIKOOrder(response.data)
        break
    }

    if (response.modal !== undefined && response.modal) {
      showSnackbar(true, response.data)
    }
  }

  static errorResponse (response) {
    logger('error', 'error response from ' + response.type, response)

    switch (response.type) {
      case 'report43':
        errorReport43(response.data)
        break
      case 'kassaSendIIKO':
        errorKassaSendIIKO(response.data)
        break
      case 'print':
        errorPrint(response.data)
        break
      case 'cancelIIKOOrder':
        errorCancelIIKOOrder(response.data)
        break
    }
  }
}

function sendBackend (url, channel, data, userAuth) {
  return this.axios({
    method: 'post',
    url: url + '/kassa/return_response',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify({
      channel: channel,
      data: JSON.stringify(data),
      user_id: userAuth.user_id,
      token: userAuth.token
    })
  })
}

function showModal (data) {
  store.state.modal.diagnostics = true
  store.dispatch('addError', data.text)
  store.dispatch('showModalWorker', data)
}

function showSnackbar (isSuccess, text) {
  store.state.snackbar.success = isSuccess
  store.dispatch('showSnackbar', text)
}

function responseSendIIKO (response) {
  sendBackend(response.apiUrl, response.returnChannel, response.data, response.userAuth)
}

function responsePrint (response) {
  if (response.message || response.message !== '') {
    showSnackbar(true, response.message)
  }
}

function errorPrint (response) {
  let message = response.message

  if (typeof response.message === 'object') {
    message = i18n.t('text.failed_request_print_by_IIKO_plugin')
  }

  showSnackbar(false, message)
}

function responseReport43 (response) {
  store.dispatch('setReport', response)
}

function errorReport43 (response) {
  let data = {
    title: response.title !== undefined ? response.title : i18n.t('errors.request_error'),
    color: 'red',
    text: response.message
  }

  if (typeof response.message === 'object') {
    data = IIKOErrors.noConnectionError()
  }

  store.state.eventBus.$emit('errorIIKO', response)
  showModal(data)
}

function responseKassaSendIIKO (response) {
  if (store.state.user.id === 624) {
    response.message = i18n.t('text.payment_was_successful_order') + response.iikoNumber
  } else {
    response.message = `${i18n.t('text.order_has_been_sent_to_IIKO')} <br> ${i18n.t('text.open_Open_Order_and_select_order')}` + response.iikoNumber
  }

  response.modal = true
  store.state.orderDialog.changeModal.show = true
  store.state.eventBus.$emit('sentToIIKO', response)
}

function errorKassaSendIIKO (response) {
  let data = {
    title: response.title !== undefined ? response.title : i18n.t('errors.request_error'),
    color: 'red',
    text: response.message
  }

  if (typeof response.message === 'object') {
    data = IIKOErrors.noConnectionError()
  }

  store.state.eventBus.$emit('errorIIKO', response)
  showModal(data)
}

function responseCancelIIKOOrder (response) {
  if (response.status !== 'success') {
    errorCancelIIKOOrder(response)
    return
  }

  showSnackbar(true, 'Заказ успешно отменен')
  store.state.eventBus.$emit('canceledInIIKO', response)
}

function errorCancelIIKOOrder (response) {
  let data = {
    title: response.title !== undefined ? response.title : i18n.t('errors.request_error'),
    color: 'red',
    text: response.message
  }

  if (typeof response.message === 'object') {
    data = IIKOErrors.noConnectionError()
  }

  store.state.eventBus.$emit('errorIIKO', response)
  showModal(data)
}
