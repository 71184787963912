<style lang="scss">

  @mixin tableStyle {
    padding: 0 24px;
    position: relative;
    cursor: pointer !important;
  }

  .v-alert{
    margin: 5px 0 5px 0;
    font-size: 14px;
    letter-spacing: normal;
    &.error > .v-alert__wrapper {
      text-align: left;
      color: #fff;
    }
  }

  .v-data-table > .v-data-table__wrapper > table {
    thead > tr {
      height: 56px;
      th, td {
        @include tableStyle;
        font-size: 12px;
        white-space: nowrap;
        font-weight: 500;
      }
    }

    tbody > tr {
      height: 56px;
      th, td {
        @include tableStyle;
        font-size: 13px;
      }
    }

    tfoot > tr {
      th, td{
        @include tableStyle;
      }
    }
  }

  .v-dialog > .v-card > .v-card__text{
    padding: 16px;
  }

  td.with-notice {
    min-width: 96px;
    padding-bottom: 20px !important;
    background: #f38181;
  }

  .column-notice {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px;
    font-size: 10px;
    width: 100%;
    text-align: center;
    background: #C62828;
    color: #fff;
  }

  table.table .not-confirmed {
    background: #dd6363;
    color: #fff;
  }

  .text-center {
    text-align: center;
  }

  a.v-btn{
    letter-spacing: normal;
    margin: 6px 8px;
  }

  .v-badge{
    line-height: normal;
  }

  @media only screen and (max-width: 1152px) {
    .container {
      max-width: 100%;
    }
  }

  .order-card {
    position: relative;

    .card__title {
      padding: 24px 12px 12px;
    }

    .title-wrap {
      width: 100%;
    }

    .order-number {
      color: #666;
      font-size: 0.65em;
    }
  }

  .icon.icon--small {
    font-size: 17px;
  }

  .icon.icon--xsmall {
    font-size: 12px;
  }

  .text-small {
    font-size: 11px;
  }

  .call-operator__wrap {
    position: absolute;
    bottom: 14px;
  }

  .navigation-drawer,
  .dialog {
    will-change: auto;
    transition: 0.45s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .loader-container {
    text-align: center;
    padding: 1rem 1rem 0;
  }

  .overlay,
  .dialog {
    transition: none;
  }
  .text-black {
    color: black;
  }
  .text-white {
    color: white;
  }
</style>

<template>
  <v-app id="inspire" :class="{ 'theme--dark': !isLogged }">

    <main-menu></main-menu>

    <v-main>
      <router-view></router-view>
    </v-main>

    <news-dialog
      v-if="$store && $store.state.news.dialog"
      :dialog="$store.state.news.dialog.show"
      :news="$store.state.news.dialog.news">
    </news-dialog>

    <v-dialog
      v-if="$store && dialog.show"
      v-model="dialog.show" max-width="400">
      <v-card>
        <v-card-title class="headline">{{ dialog.header }}</v-card-title>
        <v-card-text v-html="dialog.text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text="text" @click.native="dialog.show = false">{{ $t('cancel') }}</v-btn>
          <v-btn :color="dialog.btn.color" text="text"
                 @click.native="dialog.callback(); dialog.show = false">{{ dialog.btn.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-if="$store && $store.state.snackbar"
      multi-line
      :color="$store.state.snackbar.type"
      :content-class="$store.state.snackbar.type === 'warning' ? 'text-black' : 'text-white'"
      v-model="$store.state.snackbar.show">
      <template>
        <div v-html="$store.state.snackbar.message"></div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click.native="$store.state.snackbar.show = false">{{ $t('ok') }}</v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="$store && $store.state.modal.show"
      v-model="$store.state.modal.show"
      transition="dialog-bottom-transition"
      max-width="600">
      <template>
        <v-card>
          <v-toolbar
            :color="$store.state.modal.color">
            <span style="width: 100%; color:#fff" class="text-h4 text-center">{{ $store.state.modal.title }}</span>
          </v-toolbar>
          <v-card-text>
            <template v-if="$store.state.modal.list !== null">
              <v-list>
                <v-subheader class="text-h5">{{ $t('reasons') }}:</v-subheader>
                <v-list-item
                  v-for="(item, i) in $store.state.modal.list" :key="i">
                  <v-list-item-content class="pa-3">
                    <v-list-item-title v-text="item"></v-list-item-title>
                    <v-divider></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <div class="pa-12 text-h5" v-else v-html="$store.state.modal.text"></div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="$store.state.modal.show = false">
              {{ $t('close') }}</v-btn>
          </v-card-actions>
        </v-card>

        <template>
          <v-card
            class="mx-auto red--text"
            max-width="600">
            <v-card-text >
              <span class="font-italic blue--text text-- darken-4">{{ $t('text.diagnostic_info') }}<br>{{ $t('order') }} № {{ $store.state.orderDialog.order.id }} ,
                {{ $store.state.user.city_name }}, {{ $store.state.user.name }}, {{ $store.state.user.address }} ( ID {{ $store.state.user.id }} )
              </span>
            </v-card-text>
          </v-card>
        </template>

      </template>
    </v-dialog>
    <div class="text-xs-center">
      <v-dialog
        v-model="$store.state.loading"
        persistent
        width="300">
        <v-card
          color="primary"
          dark>
          <v-card-text>
            {{ $store.state.loader.text }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <modals/>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

import NewsDialog from './components/NewsDialog'
import Menu from './components/Menu'
import settings from './config/settings'
import { loadLanguageAsync } from './i18n'
import Modals from './components/Modals/Modals.vue'

export default {
  components: {
    'news-dialog': NewsDialog,
    'main-menu': Menu,
    Modals
  },
  data: () => ({
    settings: settings
  }),
  computed: {
    ...mapState({
      dialog: state => state.dialog
    }),
    ...mapGetters([
      'isLogged',
      'getLocale'
    ])
  },
  methods: {
    ...mapMutations([
      'setLocale'
    ])
  },
  watch: {
    $route (to, from) {
      this.$store.state.page = to.name
      const DEFAULT_TITLE = this.$i18n.t('main.name')
      document.title = this.$i18n.t(`menu.${to.name}`) || DEFAULT_TITLE
    }
  },
  created () {
    const lang = this.getLocale || this.$i18n.locale
    loadLanguageAsync(lang).then(res => {
      this.setLocale(res)
    })
  },
  mounted () {
    this.$vuetify.theme.themes.light.primary = '#ff1f2e'
    this.$vuetify.theme.themes.light.secondary = '#24276A'
    this.$vuetify.theme.themes.light.accent = '#ff1f2e'
    this.$vuetify.theme.themes.light.warning = '#ffc107'
  }
}
</script>

<style>
  html {
    overflow: auto;
  }
  .disable {
    position: relative;
  }

  .disable:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.05);
    content: "";
  }

  .unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .w-max-content {
    width: max-content !important;
  }
</style>
