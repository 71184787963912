<template>
  <div class="order-filters">
    <div class="base-filters">
      <div class="type-filter">

        <div class="filter-btn" :class="{active: isAll}" @click="changeTypes('all')">
          <div class="filter-btn-icon">
            <all-icon/>
          </div>
          <div>
            <span>{{ $t('all_orders') }}</span>
          </div>
        </div>

        <div class="filter-btn" :class="{active: isPickup}" @click="changeTypes('pickup')">
          <div class="filter-btn-icon">
            <pickup-icon />
          </div>
          <div>
            <span>{{ $t('pickup') }}</span>
            <span class="font-weight-bold ml-2">{{ badgesTypes.pickup }}</span>
          </div>
        </div>

        <div class="filter-btn" :class="{active: isDelivery}" @click="changeTypes('delivery')">
          <div class="filter-btn-icon">
            <delivery-icon/>
          </div>
          <div>
            <span>{{ $t('delivery') }}</span>
            <span class="font-weight-bold ml-2">{{ badgesTypes.delivery }}</span>
          </div>
        </div>
      </div>

      <div class="date-filter">
        <div class="date-ctrl prev"></div>
        <div class="date-number">
          {{ filters.date }}
        </div>
        <div class="date-ctrl next"></div>
      </div>
    </div>

    <order-statuses :badges="badgesStatuses" v-model="filters.statuses"></order-statuses>
  </div>
</template>

<script>

import orderStatuses from './OrderStatuses'
import deliveryIcon from '@/assets/order_types/delivery.svg'
import pickupIcon from '@/assets/order_types/pickup.svg'
import allIcon from '@/assets/order_types/all.svg'

export default {
  name: 'OrderFilters',
  props: {
    value: Object,
    badges: Object
  },
  components: {
    orderStatuses,
    deliveryIcon,
    pickupIcon,
    allIcon
  },
  data: () => ({
    localFilters: null
  }),
  computed: {
    filters () {
      return this.value
    },
    isAll () {
      return this.filters.type === 'all'
    },
    isDelivery () {
      return this.filters.type === 'delivery'
    },
    isPickup () {
      return this.filters.type === 'pickup'
    },
    badgesTypes () {
      return this.badges.types
    },
    badgesStatuses () {
      return this.badges.statuses
    }
  },
  methods: {
    changeTypes (value) {
      const filters = { ...this.filters, type: value }
      this.$emit('input', filters)
    }
  }
}
</script>

<style lang="scss">
.order-filters {
  display: flex;
  flex-direction: column;
  & .base-filters {
    display: flex;
    align-items: center;
  }
}

.type-filter {
  display: flex;
  & > .filter-btn {
    width: 130px;
    height: 70px;
    background-color: #fff;
    color: #7B829B;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-right: 1px solid #E3E5E8;
    box-shadow: 23px 0px 9px rgba(0, 0, 0, 0.01), 13px 0px 8px rgba(0, 0, 0, 0.05), 6px 0px 6px rgba(0, 0, 0, 0.09), 1px 0px 3px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    & > .filter-btn-icon {
      & > svg {
        fill: #7B829B;
      }
    }
    &.active {
      background-color: #1976D2;
      border-right: 1px solid #1976D2;
      color: #fff;
      & > .filter-btn-icon {
        & > svg {
          fill: #fff;
        }
      }
    }
  }
}
</style>
