<template>
  <v-dialog
    :value="visible"
    width="600"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        {{ $t('order_modal.error_box.alert_2') }}
      </v-card-title>
      <v-card-text class="body-1">
        {{ sellerName }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'OtherSellerModal',
  props: {
    visible: Boolean,
    order: Object
  },
  computed: {
    sellerName () {
      return this.order?.seller_name ?? ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
