import deliveryZone from './deliveryZone'
import pickup from './pickup'

export default {
  namespaced: true,

  modules: {
    delivery_zone: deliveryZone,
    pickup
  }
}
