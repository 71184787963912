import 'moment-timezone'
import 'moment/locale/ru'

import { i18n } from '@/i18n'

import {
  getDeviceList,
  authDeviceByCode,
  getDeviceTypesList,
  logoutDevice,
  getAuthDeviceList
} from '@/functions/devices'

export default {
  namespaced: true,

  state: {
    loading: false,
    types: [],
    devices: [],
    authDevices: [],
    codeModal: {
      visible: false,
      id: null
    }
  },

  getters: {
    getDevices (state) {
      return state.devices
    }
  },

  mutations: {
    changeLoading (state, val) {
      state.loading = val
    },
    changeVisibleCodeModal (state, val) {
      state.codeModal.visible = val
    },
    setDevices (state, val) {
      state.devices = val
    },
    setAuthDevices (state, val) {
      state.authDevices = val
    },
    setTypes (state, val) {
      state.types = val
    },
    setCodeModal (state, val) {
      state.codeModal = {
        ...state.codeModal,
        ...val
      }
    }
  },

  actions: {
    getDeviceList,
    getDeviceTypesList,
    getAuthDeviceList,
    authDeviceByCode,
    logoutDevice,
    getDevices ({ commit, dispatch, rootState }) {
      commit('changeLoading', true)

      dispatch('getDeviceList', rootState.user.id).then(response => {
        commit('setDevices', response.data)
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    getAuthDevices ({ commit, dispatch, rootState }) {
      commit('changeLoading', true)

      dispatch('getAuthDeviceList', rootState.user.id).then(response => {
        commit('setAuthDevices', response.data)
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    getDeviceTypes ({ commit, dispatch }) {
      commit('changeLoading', true)

      dispatch('getDeviceTypesList').then(response => {
        commit('setTypes', response.data)
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    authDevice ({ commit, dispatch }, params) {
      commit('changeLoading', true)

      const data = {
        station_device_id: params.id,
        code: params.code
      }

      dispatch('authDeviceByCode', data).then(response => {
        dispatch('showSnackbar', i18n.t('text.auth_device_successfully'), { root: true })

        commit('changeVisibleCodeModal', false)
        dispatch('getAuthDevices')
      }).finally(() => {
        commit('changeLoading', false)
      })
    },
    logout ({ commit, dispatch, state }, authId) {
      commit('changeLoading', true)

      const data = {
        station_device_auth_id: authId
      }

      dispatch('logoutDevice', data).then(response => {
        dispatch('showSnackbar', i18n.t('text.logout_device_successfully'), { root: true })

        this._vm.$delete(state.authDevices, state.authDevices.findIndex(val => val.auth_id === authId))
      }).catch(e => {
        dispatch('showSnackbar', e.response.data, { root: true })
      }).finally(() => {
        commit('changeLoading', false)
      })
    }
  }
}
