<template>
    <v-dialog :value="visible" :persistent="persistent" :width="width" :disabled="loading">
        <template>
            <v-card>
                <v-card-title class="text-h5">{{ title }}</v-card-title>

                <v-card-text class="py-7">
                    <slot/>

                    <v-alert
                        v-if="hasAlert"
                        border="left"
                        :type="alert.type"
                        colored-border
                        elevation="2"
                    >{{ alert.text }}</v-alert>
                </v-card-text>

                <v-card-actions class="flex justify-space-around pb-4">
                    <slot name="buttons"></slot>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>

export default {
  name: 'BaseTemplateModal',
  props: {
    type: {
      type: String,
      default: '',
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    loading: {
      type: Boolean,
      required: true,
      default: false
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    persistent: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '35%'
    },
    alert: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    hasAlert () {
      if (!this.alert) return false

      return this.alert?.text !== '' ?? false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
