<template>
  <v-container>
    <v-row>
      <v-col>
        <v-data-table
          :loading="loading"
          :class="{'disable': loading}"
          hide-default-footer
          :headers="headers"
          :items="zones"
          class="elevation-1">
          <template slot="item.color" slot-scope="{ item }">
            <div class="d-flex justify-center">
              <div class="square" :style="`background-color: ${item.fillColor}`"></div>
            </div>
          </template>
          <template slot="item.delivery_time" slot-scope="{ item }">
            <div class="d-flex justify-center">
              <span v-if="item.custom_delivery_time !== null">{{ $t('time.delay_minutes', { time: item.custom_delivery_time })}}</span>
              <span v-else>{{ $t('time.delay_minutes', { time: item.delivery_time }) }}</span>
            </div>
          </template>
          <template slot="item.actions" slot-scope="{ item }">
            <div v-if="!item.inactive.status" class="d-flex justify-center">
              <v-btn
                color="primary"
                small
                @click="edit(item.id)">
                <span v-if="user.kassir_can_change_delivery_time">
                    {{ $t('time.update') }}
                </span>
                <span v-else>
                    {{ $t('time.view') }}
                </span>
              </v-btn>
            </div>

            <div v-else class="d-flex align-center flex-column my-2">
              <v-btn
                v-if="item.inactive.status"
                color="primary"
                small
                @click="restoreZone(item.id)">
                {{ $t('stop_list.restore_sales') }}
              </v-btn>

              <span style="font-size: 12px;">{{ $t('stop_list.sales_restores_in', { minutes: item.inactive.inactiveTime }) }}</span>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <time-modal
      :visible="modal.visible"
      :zone="modal.zone"
      @close="modal.visible = false"
    ></time-modal>
  </v-container>
</template>

<script>
import DeliveryTimeModal from './DeliveryTimeModal'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeliveryTime',
  components: {
    'time-modal': DeliveryTimeModal
  },
  props: {
    tab: String
  },
  computed: {
    ...mapGetters('times/delivery_time', [
      'modal',
      'loading',
      'zones',
      'headers'
    ]),
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions('times/delivery_time', [
      'getDeliveryZones',
      'restore',
      'edit'
    ]),
    restoreZone (id) {
      this.restore(id)

      this.zones.find(zone => zone.id === id).inactive = {
        status: false,
        inactiveTime: null
      }
    },
    checkTab (val) {
      if (val === 'delivery_time') {
        if (this.zones.length === 0) {
          this.getDeliveryZones()
        }
      }
    }
  },
  watch: {
    tab (val) {
      this.checkTab(val)
    },
    loading (val) {
      this.$emit('loading', val)
    }
  },
  created () {
    this.checkTab(this.tab)
  }
}
</script>

<style scoped>
  .square {
    width: 20px;
    height: 20px;
    border: 1px solid #bdbdbd;
  }
</style>
