<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-between">
        <div>
          <v-icon>mdi-label</v-icon>
          <span class="mx-2">{{ $store.state.user.brand }}</span>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-space-between">
        <div>
          <v-icon>mdi-store</v-icon>
          <span class="mx-2">{{ shopName }}</span>
        </div>
        <div>
          <small>ID:{{ $store.state.user.id }}</small>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <v-icon>mdi-information-outline</v-icon>
          <span class="mx-2">{{ $t('version.app') }}: {{ $store.state.settings.VERSION }}</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'AboutProgram',
  data () {
    return {
      information: [],
      infoTooltip: {
        visible: false,
        data: []
      }
    }
  },
  computed: {
    shopName () {
      return this.$store.state.user.city_name + ', ' + this.$store.state.user.full_name
    }
  }
}
</script>

<style scoped>

</style>
