
export default {
  namespaced: true,

  state: {
    confirm: {
      visible: false,
      title: '',
      type: 'info',
      text: '',
      alert: {
        text: '',
        icon: false,
        type: 'info'
      },
      buttons: {
        yes: {
          text: '',
          action: () => null,
          color: 'success'
        },
        no: {
          text: '',
          action: () => null,
          color: 'error'
        }
      }
    },
    prompt: {
      visible: false,
      title: '',
      type: 'info',
      label: '',
      alert: {
        text: '',
        icon: false,
        type: 'info'
      },
      fields: [
        {
          slug: '',
          label: '',
          value: ''
        }
      ],
      buttons: {
        enter: {
          text: '',
          action: () => null,
          color: 'success'
        },
        cancel: {
          text: '',
          action: () => null,
          color: 'error'
        }
      }
    }
  },
  mutations: {
    setOptions (state, { type, value }) {
      if (typeof value !== 'object') value = false

      state[type] = value
    },
    setVisible (state, { type, value }) {
      if (typeof value !== 'boolean') value = false

      state[type].visible = value
    },
    clear (state, type) {
      state[type].visible = false
      state[type].loading = false
      state[type].title = ''
      state[type].type = 'info'
      state[type].label = ''
      state[type].text = ''
      state[type].alert = {
        text: '',
        icon: false,
        type: 'info'
      }

      for (const key in state[type].buttons) {
        state[type].buttons[key].text = ''
        state[type].buttons[key].action = () => null
        state[type].buttons[key].color = 'info'
      }
    }
  },
  actions: {
    showConfirmModal ({ state, commit }, options) {
      commit('setOptions', { type: 'confirm', value: options })
      commit('setVisible', { type: 'confirm', value: true })
    },
    showPromptModal ({ state, commit }, options) {
      commit('setOptions', { type: 'prompt', value: options })
      commit('setVisible', { type: 'prompt', value: true })
    }
  }
}
