<template>
  <v-container :class="{disable: loading}">
    <v-row>
      <v-col>
          <div v-if="!inactive.status">
              <span class="font-weight-bold">
                  {{ $t('cooking_time') }} — {{ $t('time.delay_minutes', { time: time.cooking }) }}
              </span><br>
              <span v-if="user.kassir_can_change_cooking_time">
                  {{ $t('text.not_have_time_increase_cooking_time') }}
              </span>
          </div>

          <div v-else>
              <span class="font-weight-bold">
                  {{ $t('stop_list.sales_restores_in', { minutes: inactive.inactiveTime }) }}
              </span>
          </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <time-slider
          :max="time.max"
          :min="time.min"
          :step="time.step"
          :min-value="time.minValue"
          :disabled="loading || inactive.status || !user.kassir_can_change_cooking_time"
          v-model="time.cooking"
        ></time-slider>
      </v-col>
    </v-row>

    <v-row class="my-5" align="center" v-if="update.visible">
      <v-col :cols="12" class="d-flex align-center">
        <span class="mr-2">{{ $t('valid_until') }}</span>

        <div>
          <v-select
            dense
            solo
            hide-details="auto"
            :placeholder="$t('select')"
            v-model="update.form.date"
            :items="dates"
          ></v-select>
        </div>

        <div class="d-inline ml-1">
          <input type="time" class="custom-time-picker" v-model="update.form.time">
        </div>
      </v-col>
    </v-row>

    <v-row v-if="stopList.visible && ! inactive.status && user.kassir_can_stop_pickup">
      <v-col :cols="12">
        <div id="warning-alert" style="width: 100%" class="d-flex align-items-center" @click="shopToStopList()">
          <v-col :xs="2" :cols="1">
            <v-icon id="warning-lamp">mdi-lightbulb-on-outline</v-icon>
          </v-col>
          <v-col :xs="9" :cols="10" class="d-flex align-center">
            <span>{{ $t('text.if_cant_cook_faster_better_close_shop') }}</span>
          </v-col>
          <v-col :cols="1" class="d-flex align-center justify-center">
            <v-icon>mdi-chevron-right</v-icon>
          </v-col>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="update.visible || inactive.status">
      <v-col>
        <v-btn v-if="!inactive.status" @click="save" color="success">{{ $t('save') }}</v-btn>
        <v-btn v-else @click="restore" color="primary">{{ $t('stop_list.restore_sales') }}</v-btn>
      </v-col>
    </v-row>

    <stop-list-modal
      :loading="loading"
      :title="$store.state.user.name"
      :visible="stopList.modal.visible"
      @save="saveStopList"
      @close="stopList.modal.visible = false">
    </stop-list-modal>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import StopListPickupModal from '../StopList/Modals/StopListModal'
import CustomTimeSlider from './CustomTimeSlider'

export default {
  name: 'CookingTime',
  components: {
    'stop-list-modal': StopListPickupModal,
    'time-slider': CustomTimeSlider
  },
  props: {
    tab: String
  },
  computed: {
    ...mapState('times/cooking_time', {
      loading: state => state.loading,
      inactive: state => state.inactive,
      time: state => state.time,
      update: state => state.update,
      stopList: state => state.stopList
    }),
    ...mapGetters(['user']),
    ...mapGetters('times/cooking_time', [
      'timeMarks',
      'min',
      'dates'
    ])
  },
  methods: {
    ...mapMutations('times/cooking_time', [
      'setTime',
      'setDefaultTime',
      'setUpdate',
      'changeStopListStatus',
      'clearStore'
    ]),
    ...mapActions('times/cooking_time', [
      'getInfoAboutClosing',
      'save',
      'getCustomTime',
      'shopToStopList',
      'restore',
      'updateValue',
      'saveStopList'
    ]),
    checkTab (val) {
      if (val === 'cooking_time') {
        this.setTime({
          cooking: this.min,
          minValue: this.min
        })

        this.getCustomTime()
        this.getInfoAboutClosing()
      } else {
        this.clearStore()
      }
    }
  },
  watch: {
    tab (val) {
      this.checkTab(val)
    },
    'update.visible' (val) {
      if (val) {
        this.setDefaultTime()
      }
    },
    'time.cooking' (val, old) {
      if (val === this.time.default) {
        this.setUpdate({ visible: false })
      } else if (old && val && val >= this.time.minValue) {
        this.setUpdate({ visible: true })
      }

      if (val >= this.time.max - 10) {
        this.changeStopListStatus(true)
      } else {
        this.changeStopListStatus(false)
      }
    },
    loading (val) {
      this.$emit('loading', val)
    }
  },
  created () {
    this.checkTab(this.tab)
  }
}
</script>

<style scoped>
  #warning-lamp {
    color: orange;
    font-size: 40px;
  }
  #warning-alert {
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 8px;
  }
  .custom-time-picker {
    height: 38px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
</style>
