<style lang="scss">
    .article-text {
        margin-bottom: 4rem;
        font-family: sans-serif !important;

        h1, h2, h3, h4, h5, p, b, ul, li, a, strong, i, em {
            font-family:  Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
        }

        img {
            max-width: 100%;
            height: auto;
            margin: 1rem auto;
            border-radius: 7px;
        }
    }
</style>

<template>
    <v-dialog
      :value="dialog.show" @input="$emit('input', $event.target.value)"
      transition="none"
      :overlay=false
      scrollable
      max-width="'640px'">
        <v-card>
            <v-card-text class="fill-height">
                <v-layout row wrap fill-height>
                    <v-flex xs12 sm10 offset-sm1 v-if="article">
                        <h3>{{ article.title }}</h3>
                        <div class="article-text" v-html="article.full_text"></div>
                        <v-divider></v-divider>
                        <div class="text-xs-right">
                            <v-btn
                                   color="green dark-1"
                                   dark
                                   @click="read">
                                Прочитано &nbsp;
                                <v-icon dark>remove_red_eye</v-icon>
                            </v-btn>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
  props: ['dialog', 'article'],
  methods: {
    read () {
      this.$store.commit('readArticle', this.article)
      // eslint-disable-next-line vue/no-mutating-props
      this.dialog.show = false
    }
  }
}
</script>
