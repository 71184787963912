<template>
  <div class="payment" :class="{paid: isPaid, selected: isSelected, notConsider: !isConsider}">
      <v-row @click="openKeyboard" align="center" dense>
          <v-col md="6">
            <div>
              <div class="d-flex align-center">
                  <span>{{ payment.name }}</span>
                  <v-chip x-small class="ms-2" v-if="!isConsider" color="red">
                    <span style="color: white!important; font-size: 10px!important;">{{ $t('not_considered') }}</span>
                  </v-chip>
              </div>
            </div>
          </v-col>
          <v-col md="3" style="text-align: end;">
              <div style="font-size: 19px; white-space: nowrap;">{{ $n(payment.is_cash ? payment.amount_change : payment.amount, 'currency') }}</div>
              <div v-if="isPaid" style="font-size: 12px; line-height: 1.15;">{{ $t('paid') }}</div>
          </v-col>
          <v-col md="3" style="text-align: end">
              <template v-if="!isDisabled">
                  <v-btn @click.prevent.stop icon @click="$emit('delete')">
                      <v-icon size="26" dark>mdi-close</v-icon>
                  </v-btn>
              </template>
              <template v-else>
                  <v-btn icon>
                      <v-icon size="26">mdi-lock-outline</v-icon>
                  </v-btn>
              </template>
          </v-col>
      </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SelectPaymentTypeItem',
  props: {
    remnant: Number,
    payment: Object,
    isSelected: Boolean,
    isConsider: Boolean,
    change: Number
  },
  computed: {
    isPaid () {
      return this.payment.status_payment === 1
    },
    isError () {
      return this.payment.status_payment > 1
    },
    isLocked () {
      return this.payment.is_locked
    },
    isDisabled () {
      return this.isPaid || this.isLocked
    },
    amount () {
      return parseFloat(this.payment.amount)
    }
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    openKeyboard () {
      if (this.isDisabled) {
        return true
      }
      this.$emit('open-keyboard')
    },
    updateAmount () {
      this.$emit('update-amount', this.amount)
    }
  }
}
</script>

<style scoped lang="scss">
.payment {
  user-select: none !important;
  //height: 76px;
  background: #BBC4C8;
  padding: 11px 10px;
  margin-bottom: 2px;

  &.paid {
    background: #AEE3A1;
  }

  &.selected {
    background: #CFD8DC;
  }
  &.notConsider {
    background: #FFCDD2;
  }

  * {
    color: black !important;
    font-size: 16px;
  }
}
</style>
