<template>
  <v-dialog
    :value="visible"
    width="500"
    @input="$emit('close')">
    <v-card>
      <v-card-title
        class="headline red lighten-2"
        primary-title>
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="$emit('close')">
          {{ $t('well') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ErrorModal',
  props: {
    visible: Boolean,
    title: String,
    message: String
  }
}
</script>

<style scoped>

</style>
