<template>
  <div class="order-statuses">
    <div class="status" :class="{selected: statusIsSelected(status.value)}"
         v-for="(status, index) in statuses" :key="'status-' + index"
        @click="selectStatus(status.value)">
      <div class="status-name">
        {{ status.label }}
      </div>
      <span class="status-badge font-weight-bold ml-2">{{ getBadge(status) }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderStatuses',
  props: {
    badges: Object,
    value: Array
  },
  computed: {
    ...mapGetters({
      statuses: 'statusesForFilters'
    }),
    filters () {
      return this.statuses.reduce((out, status) => {
        const ids = status.includes.filter(id => this.value.includes(id))
        if (ids.length) {
          out = [...out, status.value]
        }
        return out
      }, [])
    },
    statusesFromSlug () {
      return this.statuses.reduce((out, status) => {
        out[status.value] = status
        return out
      }, {})
    }
  },
  methods: {
    statusIsSelected (value) {
      return this.filters.includes(value)
    },
    selectStatus (value) {
      let data = [...this.filters, value]
      if (this.statusIsSelected(value)) {
        data = data.filter(slug => slug !== value)
      }
      const ids = data.reduce((out, slug) => {
        out = [...out, ...this.statusesFromSlug[slug].includes]
        return out
      }, [])

      this.$emit('input', ids)
    },
    getBadge (status) {
      let count = 0

      status.includes.map(id => {
        count += this.badges[id]
      })

      return count
    }
  }
}
</script>

<style scoped lang="scss">
.order-statuses {
  display: flex;
  height: 50px;
  border-bottom: 1px solid #E3E5E8;
  .status {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-right: 1px solid #E3E5E8;
    border-top: 1px solid #E3E5E8;
    padding: 0 1rem;
    cursor: pointer;
    white-space: nowrap;
    &.selected {
      background-color: #1976D2;
      border-right: 1px solid #1976D2;
      border-top: 1px solid #1976D2;
      color: #fff;
    }
  }
}
</style>
