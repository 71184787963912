<template>
  <v-container grid-list-xs>
    <v-row>
      <v-col xs="6" class="d-flex justify-center">
        <v-btn class="reports-btn" color="success" x-large :to="{name: 'paymentReport'}">
          {{ $t('reports.report_types_payments') }}
        </v-btn>
      </v-col>
      <v-col xs="6" class="d-flex justify-center">
        <v-btn class="reports-btn" color="success" style="margin: 6px 8px;" x-large :to="{name: 'iiko43Report'}">
          {{ $t('reports.report_goods_and_types_payment') }}
        </v-btn>
      </v-col>
      <v-col xs="6" class="d-flex justify-center">
        <v-btn class="reports-btn" color="success" style="margin: 6px 8px;" x-large :to="{name: 'transferringOrdersToIikoReport'}">
          {{ $t('reports.report_transferring_orders_to_iiko') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Reports'
}
</script>

<style scoped>
  .reports-btn {
    min-width: 300px;
  }
</style>
