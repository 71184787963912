<template>
  <v-dialog
    :value="visible"
    @click:outside="close"
    @keydown.esc="close"
    persistent
    max-width="900px">
    <v-card :loading="loading" v-if="visible" :class="{disable: loading}">
      <v-card-title>
        <span class="text-h5">{{ $t('time.settings') }}</span>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tab v-for="item in items" :key="item.name">
            {{ $t(item.name) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.name">
            <v-card flat>
              <v-card-text>
                <component
                  :is="item.component"
                  :tab="items[tab].name"
                  @loading="changeLoading"
                ></component>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close">
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CookingTime from '../Times/CookingTime'
import DeliveryTime from '../Times/DeliveryTime'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  name: 'TimesModal',
  components: {
    'cooking-time': CookingTime,
    'delivery-time': DeliveryTime
  },
  data () {
    return {
      loading: false,
      clearComponents: false,
      has_delivery_zones: false,
      tab: 0
    }
  },
  computed: {
    ...mapGetters('times/delivery_time', {
      zones: 'zones'
    }),
    items () {
      const items = []

      items.push({
        name: 'cooking_time',
        component: 'cooking-time'
      })
      items.push({
        name: 'delivery_time',
        component: 'delivery-time'
      })

      return items
    }
  },
  methods: {
    ...mapActions('times/delivery_time', {
      getDeliveryZones: 'getDeliveryZones'
    }),
    close () {
      this.$emit('close')
    },
    changeLoading (val) {
      this.loading = val
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.getDeliveryZones()
      }
    }
  }
}
</script>

<style scoped>

</style>
