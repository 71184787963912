<template>
  <div class="number-keyboard">
    <div class="keyboard-header flex text-center">
      <div class="keyboard-header-label">
        {{ paymentName || $t('order_modal.payment_modal.title') }}
      </div>
    </div>
    <div class="number-keyboard-body">
      <div class="num-row result">
        <input ref="input" style="text-align: center" v-model="valueInput" readonly>
        <div class="num-col clear unselectable" :ref="getRefName('clear')" @click="pressBtn('clear')">
          <v-icon large style="color: #000">
            mdi-close
          </v-icon>
        </div>
      </div>
      <div class="d-flex">
        <div class="number-block">
          <div class="num-row" v-for="(rowButtons, buttonIndex) in keyButtons" :key="buttonIndex">
            <button class="item-button-val num-col unselectable"
                 v-for="(val) in rowButtons"
                 :key="`key-${val}`"
                 :ref="getRefName(val)"
                 @click="pressBtn(val)">
              {{ val }}
            </button>
          </div>
          <div class="num-row">
            <button class="item-button-val zero num-col unselectable" :ref="getRefName(0)" @click="pressBtn(0)">
              {{ 0 }}
            </button>
            <button class="item-button-val comma num-col unselectable" :ref="getRefName('comma')" @click="pressBtn('comma')">
              {{ ',' }}
            </button>
          </div>
        </div>
        <div class="number-add-blocks">
          <div :class="`number-add-block-item-${indexMassButton}`"
               v-for="(massButtons, indexMassButton) in keyAddButtons"
               :key="indexMassButton"
          >
            <button
              v-for="(addButton) in massButtons"
              :key="addButton"
              class="number-add-block-item-extra num-col unselectable extra"
              :ref="getRefName(`add-${addButton}`)"
              @click="pressBtn(`add-${addButton}`)"
            >
              +{{addButton}}
            </button>
          </div>
        </div>
      </div>
        <div class="num-row _2_3 bottom-buttons">
          <div class="first-button">
            <slot name="exact-amount"/>
          </div>
          <div class="second-button">
            <slot name="save"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NumberKeyboard',
  props: {
    index: {
      type: Number,
      default: -1
    },
    // eslint-disable-next-line vue/require-prop-type-constructor
    amount: Number,
    paymentName: String,
    editChange: Boolean,
    currentMaxValue: Number,
    ignoreMaxValue: Boolean
  },
  data: () => ({
    keyButtons: [
      [7, 8, 9],
      [4, 5, 6],
      [1, 2, 3]
    ],
    keyAddButtons: [
      [1, 5, 10, 50],
      [100, 500, 1000, 5000]
    ],
    edit: false,
    newValue: 0,
    newFloatVal: 0,
    maxValue: 999999.99,
    defaultMaxValue: 999999.99,
    modeMask: '',
    isFloat: false
  }),
  computed: {
    ...mapGetters([
      'getLocale'
    ]),
    countNewValue () {
      return this.newValue.toString().length
    },
    isMaxInputValue () {
      const value = this.ignoreMaxValue ? this.defaultMaxValue : this.maxValue
      return Number(this.getActualValue()) >= Number(value)
    },
    valueInput: {
      get () {
        return parseFloat(this.getActualValue()).toFixed(2).replace('.', ',')
      },
      set (valueItem) {
        if (this.isFloat) {
          this.newFloatVal = valueItem
        } else {
          this.newValue = valueItem
        }
        const value = parseFloat(this.getActualValue())

        if (this.isMaxInputValue) {
          this.resetInputToAmount(this.maxValue)
          this.$nextTick(() => {
            this.$emit('input-amount', { value: this.maxValue, index: this.index })
          })
          return
        }

        this.$emit('input-amount', { value, index: this.index })
      }
    },
    currentInputValue () {
      return this.isFloat ? this.newFloatVal : this.newValue
    }
  },
  methods: {
    getActualValue () {
      let newValue = (!this.modeMask || isNaN(this.modeMask)) ? 0 : Number(this.modeMask)
      const countZero = newValue.toString().match(/[0]*$/)[0].length
      if (Number(this.newValue) > 0) {
        let spliceVal = countZero
        if (countZero >= this.countNewValue) {
          spliceVal = this.countNewValue
        }
        const val = Number(String(newValue).slice(0, -spliceVal))
        if (Number(val)) {
          newValue = val.toString()
        }
        if (newValue) {
          newValue = newValue.toString() + this.newValue.toString()
        } else {
          newValue = this.newValue.toString()
        }
      }
      return `${newValue}.${this.newFloatVal}`
    },
    resetValues () {
      this.modeMask = ''
      this.newValue = 0
      this.newFloatVal = 0
      this.isFloat = false
    },
    getRefName (key, edit) {
      return 'key-' + key
    },
    getEl (key) {
      const ref = this.$refs[this.getRefName(key)]
      return ref[0] ?? ref
    },
    pressBtn (key) {
      const el = this.getEl(key)
      this.$nextTick(() => {
        el.classList.add('bg-gray')
        setTimeout(() => {
          el.classList.remove('bg-gray')
        }, 100)
      })
      this.valueInput = this.detectNewValue(key)
    },
    detectNewValue (key) {
      let value = ''
      if (key === 'clear') {
        this.resetValues()
        return '0'
      }
      if (key === 'comma') {
        key = '.'
        this.isFloat = true
        return this.currentInputValue
      }
      if (this.edit) { // Сброс ввода, если было переключение ввода
        this.resetValues()
        this.edit = false
      }
      const isModeMask = String(key).includes('add')
      if (isModeMask) {
        this.isFloat = false
      }
      const inputValue = Number(String(key).replace('add-', ''))
      const currentValue = this.currentInputValue

      if (isNaN(inputValue)) return this.newValue
      if (isModeMask && (!this.newValue || Number(this.newValue) === 0)) {
        this.modeMask = Number(this.modeMask) + Number(inputValue)
        return currentValue
      } else if (isModeMask && this.newValue > 0) {
        value = Number(currentValue) + Number(inputValue)
        return value
      }
      if (currentValue > 0) {
        value = Number(currentValue.toString() + inputValue.toString())
      } else {
        value = Number(inputValue)
      }
      return value
    },
    clear (len = null) {
      const strValue = this.newValue.toString()
      if (!len) {
        this.edit = false
        this.$emit('cancel')
      }
      this.valueInput = strValue.substring(0, strValue.length - len)
    },
    onCreated (v) {
      let expect = v.key
      const isComma = /^[.,]?$/.test(expect)
      if (v.key.toLowerCase() === 'enter' && this.amount) {
        this.$emit('input-amount', this.valueInput)
      }
      if (v.key.toLowerCase() === 'backspace') {
        this.clear(1)
      }

      if (!isNaN(expect) || isComma) {
        if (isComma) {
          expect = 'comma'
        }
        this.pressBtn(expect)
      } else {
        return true
      }
    },
    resetInputToAmount (newAmount = null) {
      this.resetValues()
      const amount = newAmount || this.amount
      this.newValue = parseInt(amount)
      this.newFloatVal = (amount % 1).toFixed(2).split('.')[1] || 0
      this.edit = true
    }
  },
  watch: {
    index () {
      this.resetInputToAmount()
    },
    editChange () {
      this.resetInputToAmount()
    },
    currentMaxValue (value) {
      if (value > 0) this.maxValue = value
    }
  },
  created () {
    document.addEventListener('keydown', (v) => this.onCreated(v))
    if (this.currentMaxValue > 0) this.maxValue = this.currentMaxValue
  }
}
</script>

<style scoped lang="scss">
$col_width: 77px;
$col_height: 77px;
$col_gap: 10px;

$row_width: 77px;
$row_height: 77px;
$row_gap: 10px;

$main_color: #01579B;

$margin-item-button: 4px;

.number-add-blocks{
  display: flex;
  margin-left: $margin-item-button;
}
.number-add-block-item-0{
  margin-right: 4px;
}
.number-add-block-item-extra{
  max-width: 90px;
  min-width: 90px;
  background-color: #CFD8DC;
  color: #546E7A;
  margin-bottom: $margin-item-button;
  padding: 15.5px 7px;
  font-size: 22px;
  border-radius: 4px;
  text-align: center;
  border: 2px solid transparent;
  &:focus{
    background: #afb8bd;
  }
  //grid-column: 2 span;
}
.bottom-buttons{
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.keyboard-header{
  padding: 13px 10px 0 10px;
}
.keyboard-header-label {
  font-size: 24px;
  color: #546E7A;
  @media screen and (max-width: 1030px) {
    font-size: 18px;
  }
}
.first-button{
  margin-top: 2px;
}

.keyboard-action-btn {
  font-size: 18px;
  border-radius: 4px;
  width: 100%;
  letter-spacing: normal;
  text-transform: none;
}

.number-keyboard-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-items: center;
  padding: 0 15px 15px 15px;
}

.number-block {
  width: 100%;
  //display: grid;
  //grid-template-rows: repeat(4, $row_height);
  //gap: $row_gap;
  @media screen and (max-width: 1030px) {
    //gap: 7px;
  }
}
.item-button-val{
  padding: 11px 24px;
  margin: 0 $margin-item-button $margin-item-button 0;
  font-size: 28px;
  border-radius: 4px;
  background-color: #546E7A;
  color: #fff;
  border: 2px solid transparent;
  &:focus {
    background: #2C4652;
  }
  &.zero {
    flex: 6;
  }
  &.comma{
    flex: 1;
  }
}
.bg-gray {
  background-color: #2C4652 !important;
  border: 2px solid #FFD600 !important;

  &.extra {
    background-color: #B1BABE !important;
  }
}
.num-row {
  //width: calc($col_width * 5);
  //width: 100%;
  display: flex;
  //grid-template-columns: repeat(5, $col_width);
  //gap: $col_gap;
  @media screen and (max-width: 1030px) {
    //grid-template-columns: repeat(5, 74px);
    //gap: 9px;
  }
  &._2_1 {
    grid-template-columns: 1fr $col_width;
  }
  &._2_1_2 {
    grid-template-columns: $col_width*2+$col_gap $col_width $col_width*2+$col_gap;
    @media screen and (max-width: 1030px) {
      grid-template-columns: 157px 74px 158px;
    }
    .extra {
      grid-column: span 1;
    }
  }
  &._2_3 {
    grid-template-columns: $col_width*2+$col_gap $col_width*3+($col_gap*4);
    @media screen and (max-width: 1030px) {
      grid-template-columns: 155px 250px;
    }
  }

  & ._1_2 {
    grid-template-columns: $col_width 1fr;
  }
}

.num-col {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.result {
  display: grid;
  grid-template-columns: 1fr $col_width;
  margin-bottom: 8px;
  & input {
    color: $main_color;
    font-weight: 500;
    font-size: 40px;
    height: 100%;
    width: 100%;
    outline: none;
  }

  .clear {
    border: none;
    .v-icon.v-icon{
      &:after {
        border-radius: 16px;
      }
    }
    &:focus {
      background: lightgray;
    }
    padding: 16px;
    height: 30px;
    width: 30px;
  }
}

.actions > .num-col {
  display: flex;
  justify-content: center;
}
.select {
  user-select: all;
}

.footer {
  display: grid;
  grid-template-columns: repeat(2, 50%);

  .action {
    height: 60px;
    width: 100%;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-items: center;
    align-items: center;

    & span {
      flex: 1;
    }

    &.save {
      color: #fff;
      background-color: $main_color;
    }

    &.cancel {
      color: $main_color;
      background-color: #E8EAF6;
    }
  }
}
</style>
