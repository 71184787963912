<template>
  <v-app-bar app
             :color="settings.isProduction ? 'secondary' : 'secondary'"
             dark fixed dense clipped-left
             v-if="isLogged">
    <div class="d-flex align-center" style="margin-right: auto">
      <v-btn text :to="{name: 'orders'}">
        <v-badge color="accent" :content="badges.active" :value="badges.active">
          <span>{{ $t('menu.orders') }}</span>
        </v-badge>
      </v-btn>

      <v-btn text :to="{name: 'preorders'}">
        <v-badge color="accent" :content="badges.pre" :value="badges.pre">
          <span>{{ $t('menu.preorders') }}</span>
        </v-badge>
      </v-btn>

      <v-btn text :to="{name: 'closed'}">
        <v-badge color="accent" :content="badges.closed" :value="badges.closed">
          <span>{{ $t('menu.closed') }}</span>
        </v-badge>
      </v-btn>

      <v-btn text :to="{name: 'search'}">
        <v-icon class="px-1">mdi-magnify</v-icon>
        <span>{{ $t('menu.search') }}</span>
      </v-btn>

      <v-btn text :to="{name: 'reports'}">
        <v-icon class="px-1">mdi-credit-card-outline</v-icon>
        <span>{{ $t('menu.reports') }}</span>
      </v-btn>

      <v-btn
        text
        @click="visibleTimesModal = true">
        <v-icon class="px-1">mdi-clock-outline</v-icon>
        <span>{{ $t('cooking_time') }}</span>
      </v-btn>
    </div>
    <div class="d-flex align-center" style="margin-left: auto">
      <v-btn icon @click="refresh">
        <v-progress-circular
          indeterminate
          color="red"
          size="20"
          :width="3"
          v-if="isOrdersLoading"
        ></v-progress-circular>
        <v-icon v-else>mdi-refresh</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on:menu }">
          <v-btn fab small color="primary" v-on="{ ...menu }">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list min-width="270">
          <v-list-item>
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-icon>mdi-web</v-icon>
                <span class="mx-2">
                  {{ $t('language') }}: <locale-switcher class="pl-2"></locale-switcher>
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="visibleToolsModal = true">
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-icon>mdi-cog</v-icon>
                <span class="mx-2">{{ $t('tools') }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item target="_blank"
                       href="https://docs.google.com/presentation/d/1wCEfhUmrvnoN2LeQ5N6p-fMhMMmtj5-YvM1O3raByF4/edit">
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-icon>mdi-help-circle-outline</v-icon>
                <span class="mx-2">{{ $t('menu.training') }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-content>
              <div class="d-flex align-center">
                <v-icon>mdi-logout</v-icon>
                <span class="mx-2">{{ $t('logout') }}</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <tools-modal :visible="visibleToolsModal" @close="visibleToolsModal = false"></tools-modal>
    <times-modal :visible="visibleTimesModal" @close="visibleTimesModal = false"></times-modal>
  </v-app-bar>
</template>

<script>

import LocaleSwitcher from './LocaleSwitcher'
import ToolsModal from './Modals/ToolsModal'
import TimesModal from './Modals/TimesModal'
import { mapActions, mapGetters } from 'vuex'
import settings from '../config/settings'

export default {
  name: 'Menu',
  components: {
    LocaleSwitcher,
    ToolsModal,
    TimesModal
  },
  computed: {
    ...mapGetters([
      'isLogged',
      'isOrdersLoading',
      'isChangeOpen',
      'badges'
    ])
  },
  data () {
    return {
      settings,
      visibleToolsModal: false,
      visibleTimesModal: false
    }
  },
  methods: {
    ...mapActions([
      'refresh',
      'logout'
    ])
  }
}
</script>
