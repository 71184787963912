import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'

import settings from '@/config/settings'
import { deleteToken, sendTokenToServer } from '../functions/webservices/firebase'

export default class Firebase {
  constructor (id) {
    this.vapidKey = settings.FIREBASE_VAPID_KEY
    this.id = id

    this.app = initializeApp(settings.FIREBASE_CONFIG)
    this.analytics = getAnalytics(this.app)

    isSupported().then(response => {
      if (response) {
        this.InitializeFireBaseMessaging()
      }
    })
  }

  InitializeFireBaseMessaging () {
    this.messaging = getMessaging(this.app)
    this.GetToken()

    onMessage(this.messaging, (payload) => {
      // eslint-disable-next-line no-new
      const notification = new Notification(payload.notification.title, payload.notification)

      notification.onclick = function (ev) {
        notification.close()
      }
    })
  }

  GetToken () {
    getToken(this.messaging, { vapidKey: this.vapidKey }).then(newToken => {
      const token = window.localStorage.getItem('sentFirebaseMessagingToken') ?? null
      if (newToken !== token) {
        if (token !== null && token !== '') {
          deleteToken(token)
        }

        this.sendTokenToServer(newToken ?? false)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  sendTokenToServer (currentToken) {
    if (!this.isTokenSentToServer(currentToken)) {
      sendTokenToServer(this.id, currentToken).then(res => {
        this.setTokenSentToServer(currentToken)
      }).catch(() => {
        this.setTokenSentToServer(false)
      })
    }
  }

  isTokenSentToServer (currentToken) {
    return window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken
  }

  setTokenSentToServer (currentToken) {
    window.localStorage.setItem('sentFirebaseMessagingToken', currentToken || '')
  }
}
