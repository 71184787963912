<template>
  <div class="order-table-header">
    <div v-for="(header, index) in headers" :key="'order-table-header-' + index"
      class="header-col"  @click="$emit('sort', header.value)" :style="{'text-align': header.align}">
      {{ header.text }}
      <span class="header-sort" :class="{ visible: sort === header.value }">
        <v-icon>
          <template v-if="sortType === 'asc'">mdi-arrow-down</template>
          <template v-else>mdi-arrow-up</template>
        </v-icon>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderTableHeader',
  props: {
    headers: Array,
    sort: String || null,
    sortType: String || null
  }
}
</script>

<style scoped lang="scss">
.order-table-header {
  background-color: #0d47a1;
  color: #fff;
  height: 30px;
  display: grid;
  grid-template-columns: 160px 150px 1fr 140px 140px 140px;
  border-bottom: 1px solid #1B2B61;
}

.header-col {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  padding: 0 16px;
  cursor: pointer;

  &:hover {
    .header-sort {
      i {
        color: #fff;
      }
    }
  }
}

.header-sort {
  i {
    font-size: 18px;
    color: transparent;
  }
  &.visible {
    i {
      color: #fff;
    }
  }
}

.order-status-col {
  justify-content: start;
}

.order-address-col {
  justify-content: start;
}

</style>
