// eslint-disable-next-line import/no-webpack-loader-syntax
import Worker from 'worker-loader!@/main.worker'
import Firebase from './Firebase'
import WorkerHandling from './WorkerHandling'
import WebsocketHandling from './WebsocketHandling'
import settings from '@/config/settings'
import store from '@/store'
import { i18n } from '../i18n'
import Centrifuge from 'centrifuge'

export default class WebServices {
  async connect (userId, token) {
    this.userId = userId
    this.token = token

    await this._centrifuge()
    await this._worker()
    await this._firebase()
  }

  async disconnect () {
    await this._centrifugeDisconnect()
    await this._workerDisconnect()
  }

  async _worker () {
    let worker = await store.getters.getWorker

    if (!worker) {
      worker = new Worker()

      await store.commit('setWorker', worker)

      worker.onmessage = (event) => {
        if (event.data.status === 'success') {
          WorkerHandling.successResponse(event.data)
        } else if (event.data.status === 'error') {
          WorkerHandling.errorResponse(event.data)
        }
        store.state.loading = false
      }

      worker.onerror = (error) => {
        console.log(`${i18n.t('error')}: ${error}`)
      }
    }
  }

  async _workerDisconnect () {
    const worker = store.getters.getWorker

    if (worker) worker.terminate()
  }

  async _firebase () {
    const firebase = await store.getters.getFirebase

    if (!firebase) {
      store.commit('setFirebase', new Firebase(store.state.user.id))
    }
  }

  async _centrifuge () {
    try {
      let centrifuge = await store.getters.getCentrifuge

      if (!centrifuge) {
        await store.commit('setCentrifuge', new Centrifuge(settings.CENTRIFUGE_HOST + '/connection/websocket'))

        centrifuge = await store.getters.getCentrifuge
        centrifuge.setToken(this.token)
        centrifuge.on('connect', (ctx) => {
          console.log('centrifuge connected', ctx)
          centrifuge.subscribe('shops', function (data) {
            console.log(data)
          })

          centrifuge.subscribe('shop.' + this.userId, (response) => (new WebsocketHandling(response)).handle())
        })

        centrifuge.connect()
      }
    } catch (e) {
      console.log(e)
    }
  }

  async _centrifugeDisconnect () {
    const centrifuge = store.getters.getCentrifuge

    if (centrifuge) {
      centrifuge.disconnect()
      console.log('centrifuge disconnected')
    }
  }
}
