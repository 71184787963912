<template>
  <div class="d-flex">
    <v-btn
      :disabled="minute <= minValue || disabled"
      depressed
      icon
      x-large
      elevation="2"
      @click="updateValue(minute - step)"
    >
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <v-slider
      :disabled="disabled"
      v-model="minute"
      :max="max"
      :min="min"
      :step="step"
      :track-color="track_color"
      thumb-label
      ticks
      :tick-labels="timeMarks"
      @change="updateValue"
      style="margin-top: 11px"
    >
    </v-slider>
    <v-btn
      :disabled="minute >= max || disabled"
      depressed
      icon
      x-large
      elevation="2"
      @click="updateValue(minute + step)"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'CustomTimeSlider',
  props: {
    disabled: Boolean,
    min: Number,
    max: Number,
    step: Number,
    minValue: Number,
    value: Number
  },
  computed: {
    timeMarks () {
      const labels = []
      labels[this.min / 5] = `${this.min}`
      labels[this.minute / 5] = this.$t('time.delay_minutes', { time: this.minute })
      labels[this.max / 5] = `${this.max}`

      return labels
    }
  },
  data: () => ({
    minute: 0,
    track_color: '#bdbdbd'
  }),
  methods: {
    updateValue (val) {
      if (val <= this.minValue) {
        val = this.minValue
      }

      this.$emit('input', val)
    }
  },
  watch: {
    minute (val) {
      this.$emit('input', val)
    },
    value (val) {
      this.minute = val
    }
  },
  mounted () {
    if (this.value !== null || this.value !== 0) {
      this.minute = this.value
    }
  }
}
</script>

<style scoped>

</style>
