import axios from 'axios'
import settings from '../../config/settings'

const API_HOST = settings.API_HOST + '/kassa/notification/'

export function deleteToken (token) {
  return axios.delete(API_HOST + 'deleteToken', {
    params: {
      token: token
    }
  })
}

export function sendTokenToServer (id, token) {
  return axios.post(API_HOST + 'saveToken/' + id, {
    token: token
  })
}
