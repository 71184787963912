<template>
  <div class="auth-devices mt-5">
    <div class="auth-devices-label">
      {{ $t('authorized_devices') }}
    </div>

    <div class="auth-devices-table">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">{{ $t('name') }}</th>
            <th class="text-left">{{ $t('type') }}</th>
            <th class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr :class="{ disabled: loading }"
            v-for="item in items"
            :key="item.auth_id">
            <td>{{ item.name }}</td>
            <td>{{ $t('device.' + item.type_slug) }}</td>
            <td class="text-center">
              <v-btn
                small
                color="primary"
                elevation="2"
                @click="logout(item.auth_id)">
                {{ $t('logout') }}
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AuthDevices',
  props: {
    items: Array
  },
  computed: {
    ...mapState('device', {
      loading: state => state.loading
    })
  },
  methods: {
    ...mapActions('device', [
      'getDeviceTypes',
      'getAuthDevices',
      'logout'
    ])
  }
}
</script>

<style scoped>
.auth-devices {
  height: 50%;
}
.auth-devices-label {
  font-weight: bold;
  margin-bottom: 1rem;
}
.auth-devices-table {
  max-height: 260px;
  overflow-y: auto;
}
</style>
