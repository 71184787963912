<template>
  <v-container grid-list-xs fluid>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-text>
            <v-layout class="mb-5" row justify-center>
              <v-flex xs6>
                <v-alert
                  :value="true"
                  color="info"
                  icon="mdi-information"
                  outlined>
                  {{ $t('reports.desc_rescheduled') }}
                </v-alert>
              </v-flex>
            </v-layout>

            <v-layout justify-center>
              <v-flex xs6>
                <v-row>
                  <v-dialog v-model="datePickerVisible" width="290px">
                    <template v-slot:activator="{on:datePickerVisible}">
                      <v-text-field
                        v-on="{...datePickerVisible}"
                        :label="$t('payment_report.from_date')"
                        v-model="filters.date"
                        prepend-icon="mdi-calendar"
                        readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="filters.date = formatDate($event)" :locale="$i18n.locale" scrollable actions>
                      <template>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-row>
                            <v-btn text color="primary" @click="($event) => { datePickerVisible = false;  }">{{ $t('cancel') }}</v-btn>
                            <v-btn text color="primary" @click="($event) => { datePickerVisible = false; }">{{ $t('ok') }}</v-btn>
                          </v-row>
                        </v-card-actions>
                      </template>
                    </v-date-picker>
                  </v-dialog>
                </v-row>
                <v-row justify="space-between" class="mb-5">
                  <v-checkbox
                    v-model="filters.rescheduled_successfully"
                    :label="$t('reports.filters.show_rescheduled_successfully')"
                    color="red"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filters.rescheduled_2_or_more_times"
                    :label="$t('reports.filters.show_rescheduled_2_or_more_times')"
                    color="red"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filters.not_rescheduled"
                    :label="$t('reports.filters.show_not_rescheduled')"
                    color="red"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="filters.not_cancellations"
                    :label="$t('reports.filters.not_show_cancelled')"
                    color="red"
                    hide-details
                  ></v-checkbox>
                </v-row>
              </v-flex>
            </v-layout>

            <v-layout row justify-center>
              <v-flex xs6>
                <v-btn color="success" block dark large @click="getReport()">{{ $t('reports.generate_report') }}</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row justify-center class="mb-5">
        <v-flex xs6>
          <v-data-table
            :headers="statisticHeaders"
            :items="statisticTable"
            :no-data-text="$t('table.no_information_available')"
            :loading-text="$t('loading')"
            disable-pagination
            disable-filtering
            disable-sort
            hide-default-footer
            dense
            class="elevation-1"
          >
            <template slot="item.amount" slot-scope="{ item }">
              {{ $n(item.amount, 'currency')}}
            </template>
          </v-data-table>
        </v-flex>
    </v-layout>

    <v-layout row justify-center>
      <v-flex xs12>
        <v-data-table
          :headers="tableHeaders"
          :items="reportData"
          :items-per-page="-1"
          :no-data-text="$t('table.no_information_available')"
          :loading-text="$t('loading')"
          :footer-props="{
            itemsPerPageAllText: $t('all'),
            itemsPerPageText: $t('pagination.entries_on_page')
          }"
          :loading="loading"
          hide-default-footer
          dense
          class="elevation-5"
        >
          <template slot="item.payment_types" slot-scope="{ item }">
            <span v-for="(payment, index) in item.payment_types" :key="index">
              {{ payment.name }}
            </span>
          </template>
          <template slot="item.amount" slot-scope="{ item }">
            {{ $n(item.amount, 'currency')}}
          </template>
          <template slot="item.iiko_ids" slot-scope="{ item }">
            <span>{{ item.iiko_ids.toString() }}</span>
          </template>
          <template slot="item.status" slot-scope="{ item }">
              <span v-if="item.status === 'rescheduled_successfully'" style="color: green">{{ $t('reports.statuses.rescheduled_successfully')}}</span>
              <span v-if="item.status === 'rescheduled_2_or_more_times'" style="color: orange">{{ $t('reports.statuses.rescheduled_2_or_more_times')}}</span>
              <span v-if="item.status === 'not_rescheduled'" style="color: red">{{ $t('reports.statuses.not_rescheduled')}}</span>
          </template>
          <template slot="item.actions" slot-scope="{ item }">
              <v-btn @click="openOrder(item)" class="mr-5">{{ $t('reports.btn.open') }}</v-btn>
              <v-btn v-if="item.status === 'not_rescheduled'" @click="sendToIiko(item)">{{ $t('reports.btn.send_to_iiko')}}</v-btn>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <orders-details-dialog
      v-if="$store && $store.state.orderDialog"
      :visible="$store.state.orderDialog.show"
      :order="$store.state.orderDialog.order"
      @close="$store.state.orderDialog.show = false"
    ></orders-details-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import settings from '@/config/settings'
import OrdersDetailsDialog from '@/components/OrdersDetailsDialog.vue'
import Order from '@/Models/Order'

export default {
  name: 'TransferringOrdersToIikoReport',
  components: { OrdersDetailsDialog },
  props: {
    visible: Boolean,
    order: Object,
    list: Array
  },
  data: () => ({
    filters: {
      date: moment().format('DD.MM.YYYY'),
      rescheduled_successfully: false,
      not_rescheduled: true,
      rescheduled_2_or_more_times: true,
      not_cancellations: true
    },
    reportData: [],
    date: null,
    datePickerVisible: false,
    loading: false
  }),
  computed: {
    statisticHeaders () {
      return [
        { text: this.$t('reports.statistic.header.type'), align: 'left', value: 'type' },
        { text: this.$t('reports.statistic.header.quantity_orders'), align: 'center', value: 'quantity_orders' },
        { text: this.$t('reports.statistic.header.amount'), align: 'center', value: 'amount' }
      ]
    },
    statisticTable () {
      const success = {
        type: this.$t('reports.statistic.names.without_errors'),
        quantity_orders: 0,
        amount: 0
      }
      const repeat = {
        type: this.$t('reports.statistic.names.repeat'),
        quantity_orders: 0,
        amount: 0
      }
      const fail = {
        type: this.$t('reports.statistic.names.not_reschedule'),
        quantity_orders: 0,
        amount: 0
      }
      this.reportData.forEach(item => {
        if (item.status === 'not_rescheduled') {
          fail.quantity_orders += 1
          fail.amount += item.amount
        } else if (item.status === 'rescheduled_successfully') {
          success.quantity_orders += 1
          success.amount += 1
        } else if (item.status === 'rescheduled_2_or_more_times') {
          repeat.quantity_orders += 1
          repeat.amount += 1
        }
      })
      return [
        success,
        repeat,
        fail
      ]
    },
    tableHeaders () {
      return [
        { text: this.$t('reports.table.column.order'), align: 'center', value: 'order_id' },
        { text: this.$t('reports.table.column.payment_type'), align: 'center', value: 'payment_types' },
        { text: this.$t('reports.table.column.amount'), align: 'center', value: 'amount' },
        { text: this.$t('reports.table.column.iiko_number'), align: 'center', value: 'iiko_ids' },
        { text: this.$t('reports.table.column.status'), align: 'center', value: 'status' },
        { text: this.$t('reports.table.column.actions'), align: 'center', value: 'actions' }
      ]
    }
  },
  methods: {
    openOrder (item) {
      this.$store.dispatch('showOrder', new Order(item.order))
    },
    sendToIiko (item) {
      this.changeLoading(true, this.$t('order_modal.loader.send_to_iiko'))

      const user = (localStorage.getItem('user')) ? JSON.safeParse(localStorage.getItem('user')) : false

      this.$store.state.worker.postMessage({
        type: 'kassaSendIIKO',
        lang: this.$i18n.locale,
        token: user.token,
        url: settings.API_HOST + '/v2/iiko/sendFront/' + item.order.id,
        params: {
          user_id: user.id,
          token: user.token
        },
        reason: item.order.iikoOrderReason,
        apiUrl: settings.API_HOST
      })
    },
    formatDate (date) {
      if (!date) {
        return null
      }

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    getReport () {
      this.clearData()
      if (!this.filters.not_rescheduled && !this.filters.rescheduled_successfully && !this.filters.rescheduled_2_or_more_times) {
        return
      }

      this.changeLoading(true)
      this.axios.post(settings.API_HOST + '/v2/iiko/transferringOrdersToIikoReport', { ...this.filters })
        .then(({ data }) => {
          this.reportData = data
        }).finally(() => this.changeLoading(false))
    },
    clearData () {
      this.reportData = []
    },
    changeLoading (visible, message) {
      this.$store.state.loader.text = message ?? this.$t('loader.loading_data')
      this.$store.state.loading = visible
    }
  }
}
</script>

<style>

</style>
