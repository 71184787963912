const API_HOST = '/kassa/device/'

export function getDeviceList (context, id) {
  return this._vm.axios.get(API_HOST + 'list/' + id)
}

export function getAuthDeviceList (context, id) {
  return this._vm.axios.get(API_HOST + 'list_authenticated/' + id)
}

export function getDeviceTypesList (context) {
  return this._vm.axios.get(API_HOST + 'types')
}

export function authDeviceByCode (context, data) {
  return this._vm.axios.post(API_HOST + 'auth', data)
}

export function logoutDevice (context, data) {
  return this._vm.axios.post(API_HOST + 'logout', data)
}
